import React, { FunctionComponent } from 'react'
import { useModal } from 'modules/common/modal/useModal'
import { Modal } from './modal'
import { ModalContext } from 'modules/common/modal/modalContext'

const { Provider } = ModalContext

const ModalProvider: FunctionComponent = ({ children }) => {
  const { modal, handleModal, modalContent } = useModal()
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
