import React, { FunctionComponent } from 'react'
import { Field } from 'formik'
import SelectField from 'modules/common/form/selectField'
import { GameTip } from 'types/gameTip'

type Props = {
  tips: GameTip[]
}

const TipSelectField: FunctionComponent<Props> = ({ tips }) => {
  return (
    <>
      <label
        htmlFor="tip"
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      >
        Saved Tips
      </label>
      <Field
        id="tipSelect"
        name="tip"
        component={SelectField}
        placeholder="Select Tip"
      >
        <option>Select Tip</option>
        {tips?.map(gameTip => (
          <option key={gameTip.id} value={gameTip.tip}>
            {gameTip.tip}
          </option>
        ))}
      </Field>
    </>
  )
}
export default TipSelectField
