import React, { FunctionComponent, useEffect, useRef } from 'react'
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown'
import { FaPause, FaPlay } from 'react-icons/fa'

type Props = {
  countDownMinutes: number
  pauseCallback: () => any
  resumeCallback: () => any
  paused: boolean
}

const GameClock: FunctionComponent<Props> = ({
  countDownMinutes,
  pauseCallback,
  resumeCallback,
  paused,
}) => {
  const clockRef = useRef<Countdown | null>(null)

  useEffect(() => {
    if (null !== clockRef && null !== clockRef.current) {
      if (paused) {
        clockRef.current.getApi().pause()
      } else {
        clockRef.current.getApi().start()
      }
    }
  }, [paused, countDownMinutes])

  const startDateTime = Date.now() + countDownMinutes * 60000
  const renderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    const timeInMinutes = hours * 60 + minutes
    return (
      <div className="flex w-64 justify-center p-6 items-center">
        {completed ? (
          <span className="text-2xl leading-6 font-bold text-pink-800">
            GAME END
          </span>
        ) : (
          <>
            {paused ? (
              <FaPlay
                className="mr-2 text-xl cursor-pointer"
                onClick={() => resumeCallback()}
              />
            ) : (
              <FaPause
                className="mr-2 text-xl cursor-pointer"
                onClick={() => pauseCallback()}
              />
            )}
            <span className="text-2xl leading-6 font-bold text-pink-800">
              {zeroPad(timeInMinutes)}:{zeroPad(seconds)}
            </span>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <Countdown
        key={startDateTime}
        autoStart={false}
        ref={clockRef}
        date={startDateTime}
        renderer={renderer}
      />
    </>
  )
}
export default GameClock
