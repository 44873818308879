import React, {
  ElementType,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { getRoutes } from 'routes/routes'
import Link from 'gatsby-link'
import SidebarSubmenu from './sidebarSubmenu'
import { IconType } from 'react-icons'
import { AppState } from 'state/rootReducer'
import { Room } from 'types/room'
import { connect } from 'react-redux'
import { Route } from 'types/route'

type IconProps = {
  icon?: IconType
  className: any
}

const Icon: FunctionComponent<IconProps> = props => {
  const { icon } = props
  if (typeof icon === 'undefined') {
    return null
  }
  const IconElement: ElementType = icon
  return <IconElement {...props} />
}

interface propsFromState {
  rooms: Room[]
}

const SidebarContent: FunctionComponent<propsFromState> = ({ rooms }) => {
  const [routes, setRoutes] = useState<Route[] | null>(null)
  useEffect(() => {
    const data = getRoutes({ rooms })
    setRoutes(data)
  }, [])
  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <a
        className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
        href="#"
      >
        Menu
      </a>
      <ul className="mt-6">
        {null !== routes &&
          routes.map(route =>
            route.routes ? (
              <SidebarSubmenu route={route} key={route.name} />
            ) : (
              <li className="relative px-6 py-3" key={route.name}>
                <Link
                  to={typeof route.path !== 'undefined' ? route.path : '#'}
                  className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                  activeClassName="text-brand dark:text-gray-100"
                >
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-brand rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  />
                  <Icon
                    className="w-5 h-5"
                    aria-hidden="true"
                    icon={route.icon}
                  />
                  <span className="ml-4">{route.name}</span>
                </Link>
              </li>
            )
          )}
      </ul>
    </div>
  )
}
const mapStateToProps = (state: AppState): { rooms: Room[] } => ({
  rooms: state.rooms.data,
})

export default connect(mapStateToProps)(SidebarContent)
