import React, { FunctionComponent, useContext } from 'react'
import {
  FaEdit,
  FaPlay,
  FaStop,
  FaPencilRuler,
  FaSave,
  FaSync,
} from 'react-icons/fa'
import Button from 'modules/common/buttons/buttonWithIcon'
import { ModalContext } from 'modules/common/modal/modalContainer'
import EditModalContainer from 'modules/room/editModalContainer'
import StartGameModalContainer from 'modules/room/startGameModalContainer'
import { AppState } from 'state/rootReducer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import {
  fetchRequest,
  setIsDesignerMode,
  setIsPolling,
} from 'modules/room/state/actions'
import { stopRequest } from 'modules/gameClock/state/actions'
import { connect } from 'react-redux'

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const RoomButtonsContainer: FunctionComponent<Props> = ({
  isDesignerMode,
  currentRoom,
  user,
  stopGame,
  setIsDesignerMode,
  startPolling,
  stopPolling,
  isPolling,
}) => {
  const { handleModal } = useContext(ModalContext)

  const openEditModal = () => {
    if (null !== currentRoom) {
      handleModal(<EditModalContainer room={currentRoom} />)
    }
  }

  const openStartGameModal = () => {
    if (null !== currentRoom) {
      handleModal(<StartGameModalContainer room={currentRoom} />)
    }
  }

  const startDesigner = () => {
    if (user && !user.isAdmin) {
      return
    }
    setIsDesignerMode(true)
  }

  const stopDesigner = () => {
    if (user && !user.isAdmin) {
      return
    }
    setIsDesignerMode(false)
  }

  const stopGameCallback = () => {
    if (typeof stopGame !== 'undefined' && null !== currentRoom) {
      stopGame({ roomId: currentRoom.id })
    }
  }

  const gaming =
    null !== currentRoom &&
    typeof currentRoom.running !== 'undefined' &&
    currentRoom.running > 0

  return (
    <>
      {isPolling ? (
        <Button icon={FaStop} className="mr-4" onClick={() => stopPolling()}>
          Stop Polling
        </Button>
      ) : (
        <Button icon={FaSync} className="mr-4" onClick={() => startPolling()}>
          Start Polling
        </Button>
      )}
      {gaming ? (
        <Button
          icon={FaStop}
          className="mr-4"
          onClick={() => stopGameCallback()}
        >
          Stop Game
        </Button>
      ) : (
        <Button
          icon={FaPlay}
          className="mr-4"
          onClick={() => openStartGameModal()}
        >
          Start Game
        </Button>
      )}
      {user.isAdmin && (
        <>
          {isDesignerMode && (
            <Button
              icon={FaSave}
              className="mr-4"
              onClick={() => stopDesigner()}
            >
              Stop Designer
            </Button>
          )}
          {!isDesignerMode && !gaming && (
            <Button
              icon={FaPencilRuler}
              className="mr-4"
              onClick={() => startDesigner()}
            >
              Start Designer
            </Button>
          )}
          <Button onClick={() => openEditModal()} icon={FaEdit}>
            Edit Room
          </Button>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
  user: state.user,
  isDesignerMode: state.currentRoom.isDesignerMode,
  isPolling: state.currentRoom.isPolling,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchCurrentRoom: ({ roomId }: { roomId: string }) =>
      dispatch(fetchRequest({ roomId })),
    stopGame: ({ roomId }: { roomId: string }) =>
      dispatch(stopRequest({ roomId })),
    setIsDesignerMode: (isDesignerMode: boolean) =>
      dispatch(setIsDesignerMode(isDesignerMode)),
    startPolling: () => dispatch(setIsPolling(true)),
    stopPolling: () => dispatch(setIsPolling(false)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomButtonsContainer)
