import React, { FunctionComponent, useContext } from 'react'
import { Device } from 'types/device'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { editRequest } from 'modules/devices/state/actions'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import EditForm from 'modules/devices/components/editForm'

type Props = {
  device: Device
} & ReturnType<typeof mapDispatchToProps>

const EditModalContainer: FunctionComponent<Props> = ({
  device,
  editDevice,
}) => {
  const { handleModal } = useContext(ModalContext)

  const editCallback = async ({ device }: { device: Device }) => {
    await editDevice({ device })
    closeModal()
    return { device }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal
        title={`Edit device ${device.name}`}
        cancelCallback={closeModal}
      >
        <EditForm device={device} submitCallback={editCallback} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    editDevice: ({ device }: { device: Device }) =>
      dispatch(editRequest({ device })),
  }
}

export default connect(null, mapDispatchToProps)(EditModalContainer)
