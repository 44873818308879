import React, { FunctionComponent } from 'react'
import SectionTitle from 'modules/common/typography/sectionTitle'
import { Operator } from 'types/operator'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import Sensors from 'modules/operator/sensorsContainer'
import OperationsGrid from 'modules/operator/operationsGrid'
import RuleChainView from 'modules/operator/components/ruleChainView'
import { CardHeader } from 'modules/common/cards/cardHeader'

type Props = {
  currentOperator: Operator
  keyPrefix: string
} & ReturnType<typeof mapStateToProps>
const OperatorViewContainer: FunctionComponent<Props> = ({
  currentOperator,
  keyPrefix,
}) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg my-4">
      <CardHeader>Operator: {currentOperator.name}</CardHeader>
      <div className="px-4 py-5 sm:p-6">
        <div className={`grid grid-cols-1 gap-4 gap-y-4 py-4`}>
          <div>
            {currentOperator.ruleChains?.map((ruleChain, index) => {
              return (
                <RuleChainView
                  key={`${keyPrefix}.ruleChains.${index}`}
                  index={index}
                  ruleChain={ruleChain}
                  keyPrefix={`${keyPrefix}.ruleChains.${index}`}
                />
              )
            })}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 gap-y-4 py-4">
          <div>
            <SectionTitle>Sensors</SectionTitle>
            <div className="grid grid-cols-1 gap-4">
              <Sensors operator={currentOperator} />
            </div>
          </div>
          <div>
            <SectionTitle>Operations</SectionTitle>
            <div className="grid grid-cols-1 gap-4">
              <OperationsGrid operator={currentOperator} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
  rooms: state.rooms.data,
  isDesignerMode: state.currentRoom.isDesignerMode,
})

export default connect(mapStateToProps)(OperatorViewContainer)
