import React, { FunctionComponent } from 'react'
import { FastField } from 'formik'
import DeviceDropdown from 'modules/devices/deviceDropdownContainer'
import { FaTrash } from 'react-icons/fa'
import BooleanField from 'modules/common/form/booleanField'
import SelectField from 'modules/common/form/selectField'
import { DeviceLibrary } from 'types/device'
import { TableRowCell } from 'modules/common/table/table'
import { FieldTypeByLibrary } from 'modules/operator/components/fieldTypeByLibrary'

type Props = {
  removeCallback?<T>(index: number): T | undefined
  formNamePrefix: string
  index: number
  library: DeviceLibrary
}

const OperationEdit: FunctionComponent<Props> = ({
  index,
  removeCallback,
  formNamePrefix,
  library,
}) => {
  return (
    <>
      <tr className="bg-white">
        <TableRowCell className="text-gray-500">
          <FastField
            component={SelectField}
            name={`${formNamePrefix}.id`}
            placeholder="Select Device"
          >
            <DeviceDropdown />
          </FastField>
        </TableRowCell>
        <TableRowCell className="text-gray-500">
          <FieldTypeByLibrary
            formNamePrefix={formNamePrefix}
            ruleLibrary={library}
          />
        </TableRowCell>
        <TableRowCell className="text-gray-500">
          <FastField
            component={BooleanField}
            name={`${formNamePrefix}.runOnce`}
          />
        </TableRowCell>
        <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
          <div className="flex items-center space-x-4">
            {typeof removeCallback !== 'undefined' && (
              <span aria-label="Edit" onClick={() => removeCallback(index)}>
                <FaTrash className="w-5 h-5" aria-hidden="true" />
              </span>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}

export default OperationEdit
