import React, { FunctionComponent } from 'react'

type ModalProps = {
  title: string
  confirmButtonText?: string
  cancelButtonText?: string
  cancelCallback?: () => any
  successCallback?: () => any
}

export const CommonModal: FunctionComponent<ModalProps> = ({
  title,
  children,
  confirmButtonText,
  cancelButtonText,
  cancelCallback,
  successCallback,
}) => {
  return (
    <>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        {typeof cancelCallback !== 'undefined' && (
          <button
            type="button"
            onClick={cancelCallback}
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="sr-only">Close</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
          <h2
            className="text-xl leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            {title}
          </h2>
          <div className="mt-2">{children}</div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
        {typeof successCallback !== 'undefined' &&
          typeof confirmButtonText !== 'undefined' && (
            <button
              onClick={successCallback}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            >
              {confirmButtonText}
            </button>
          )}
        {typeof cancelCallback !== 'undefined' &&
          typeof cancelButtonText !== 'undefined' && (
            <button
              onClick={cancelCallback}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {cancelButtonText}
            </button>
          )}
      </div>
    </>
  )
}
