import React, { FunctionComponent, useEffect, useState } from 'react'
import { Operator, Rule, SequenceRule } from 'types/operator'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { Device } from 'types/device'
import { TableRowCell } from 'modules/common/table/table'

type Props = {
  rule?: SequenceRule
  devices?: Device[]
  operators?: Operator[]
}

const RuleSequenceView: FunctionComponent<Props> = ({
  rule,
  devices,
  operators,
}) => {
  const [currentDevice, setCurrentDevice] = useState<Device | null>(null)

  useEffect(() => {
    if (devices && rule && 'device' === rule.type) {
      const deviceFound = devices.find(device => device.id === rule.id)
      if (typeof deviceFound !== 'undefined') {
        setCurrentDevice(deviceFound)
      }
    }
  }, [devices, operators, rule])

  return (
    <>
      <tr className="bg-white">
        <TableRowCell className="text-gray-500">{rule?.type}</TableRowCell>
        <TableRowCell className="font-medium text-gray-900">
          {currentDevice && currentDevice.name}
        </TableRowCell>
        <TableRowCell className="text-gray-500">
          {rule?.value?.map(sequenceValue => (
            <p key={sequenceValue}>{sequenceValue}</p>
          ))}
        </TableRowCell>
        <TableRowCell className="text-gray-500">
          {rule?.state.toString()}
        </TableRowCell>
      </tr>
    </>
  )
}
const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
  operators: state.currentRoom.data?.operators,
})

export default connect(mapStateToProps)(RuleSequenceView)
