import { DevicesActionTypes } from 'modules/devices/state/types'

import { Action, Dispatch, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { API, Auth } from 'aws-amplify'
import { addMessage } from 'modules/messages/state/actions'
import { Device } from 'types/device'
import { Relay } from 'types/relay'
import { setRelaysRequest } from 'modules/room/state/actions'
import { AppThunk } from 'types/app'

export const fetchRequest: AppThunk<null> = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: DevicesActionTypes.FETCH_REQUEST })
    try {
      const result = await API.get('DevicesService', `/`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      return dispatch({
        type: DevicesActionTypes.FETCH_SUCCESS,
        payload: result.devices,
      })
    } catch (e) {
      return dispatch({
        type: DevicesActionTypes.FETCH_ERROR,
      })
    }
  }
}

export const createRequest: AppThunk<{ deviceId: string }> = ({ deviceId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: DevicesActionTypes.REGISTER_REQUEST })
    try {
      const result = await API.post('DevicesService', `/${deviceId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      await dispatch(fetchRequest())
      dispatch(
        addMessage({
          message: {
            body: `Device ${deviceId} registered succesfully`,
            type: 'success',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.REGISTER_SUCCESS,
        payload: result.devices,
      })
    } catch (e) {
      if (e.response.status === 409) {
        dispatch(
          addMessage({
            message: {
              body: `Device with ID ${deviceId} already registered`,
              type: 'danger',
            },
          })
        )
      } else if (e.response.status === 404) {
        dispatch(
          addMessage({
            message: {
              body: `Device with ID ${deviceId} cannot be found`,
              type: 'danger',
            },
          })
        )
      } else {
        dispatch(
          addMessage({
            message: {
              body: `Cannot register Device with ID ${deviceId}`,
              type: 'danger',
            },
          })
        )
      }

      return dispatch({
        type: DevicesActionTypes.REGISTER_ERROR,
      })
    }
  }
}

export const editRequest: AppThunk<{ device: Device }> = ({ device }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: DevicesActionTypes.EDIT_REQUEST })
    try {
      const result = await API.put('DevicesService', `/${device.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: device,
      })
      await dispatch(fetchRequest())
      dispatch(
        addMessage({
          message: {
            body: `Device ${device.id} modified succesfully`,
            type: 'success',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.EDIT_SUCCESS,
        payload: result.devices,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: `Cannot modify Device with ID ${device.id}`,
            type: 'danger',
          },
        })
      )

      return dispatch({
        type: DevicesActionTypes.EDIT_ERROR,
      })
    }
  }
}

export const removeRequest: AppThunk<{ device: Device }> = ({ device }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: DevicesActionTypes.REMOVE_REQUEST })
    try {
      const result = await API.del('DevicesService', `/${device.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      await dispatch(fetchRequest())
      dispatch(
        addMessage({
          message: {
            body: `Device ${device.id} removed succesfully`,
            type: 'success',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.REMOVE_SUCCESS,
        payload: result.devices,
      })
    } catch (e) {
      if (e.response.data.message === 'Device used in room') {
        dispatch(
          addMessage({
            message: {
              body: `Cannot remove Device ${
                device.id
              }, device is used in rooms: ${e.response.data.roomId.join(', ')}`,
              type: 'danger',
            },
          })
        )
      } else {
        dispatch(
          addMessage({
            message: {
              body: `Cannot remove Device with ID ${device.id}`,
              type: 'danger',
            },
          })
        )
      }

      return dispatch({
        type: DevicesActionTypes.REMOVE_ERROR,
      })
    }
  }
}

export const editRelaysRequest: AppThunk<{ relays: Relay[] }> = ({
  relays,
}: {
  relays: Relay[]
}) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState
  ): Promise<Action> => {
    const devices = getState().devices.data
    const currentRoom = getState().currentRoom.data
    dispatch({ type: DevicesActionTypes.ADD_RELAYS_REQUEST })
    try {
      const currentRelays = devices.filter(
        device => typeof device.relayTarget !== 'undefined'
      )
      const removeRelays = currentRelays.filter(
        a => !relays.some(b => a.relayTarget === b.target)
      )
      const promises = relays
        .map(relay => {
          const relayDevice = devices.find(device => device.id === relay.source)
          if (typeof relayDevice === 'undefined') {
            return dispatch(
              addMessage({
                message: {
                  body: `Cannot find device for relay`,
                  type: 'danger',
                },
              })
            )
          } else {
            return dispatch(
              editRequest({
                device: { ...relayDevice, relayTarget: relay.target },
              })
            )
          }
        })
        .concat(
          removeRelays.map(device => {
            return dispatch(
              editRequest({
                device: {
                  ...device,
                  relayTarget: undefined,
                },
              })
            )
          })
        )
      await Promise.all(promises)

      if (typeof currentRoom !== 'undefined' && null !== currentRoom) {
        await dispatch(
          setRelaysRequest({ relays: relays, roomId: currentRoom.id })
        )
      }
      await dispatch(
        addMessage({
          message: {
            body: `Relays  modified succesfully`,
            type: 'success',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.ADD_RELAYS_SUCCESS,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: `Cannot modify relays`,
            type: 'danger',
          },
        })
      )

      return dispatch({
        type: DevicesActionTypes.ADD_RELAYS_ERROR,
      })
    }
  }
}

export const giveTipRequest: AppThunk<{ tip: string; deviceId: string }> = ({
  tip,
  device,
}: {
  tip: string
  device: Device
}) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: DevicesActionTypes.GIVE_TIP_REQUEST })

    if (!device) {
      dispatch(
        addMessage({
          message: {
            body: 'No tip device given to send tip!',
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.GIVE_TIP_ERROR,
      })
    }
    try {
      await dispatch(editRequest({ device: { ...device, state: tip } }))
      await dispatch(
        addMessage({
          message: {
            body: 'Tip sent to device ' + device.id,
            type: 'success',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.GIVE_TIP_SUCCESS,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: 'Cannot send tip to device ' + device.id,
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: DevicesActionTypes.GIVE_TIP_ERROR,
      })
    }
  }
}
