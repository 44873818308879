import { createContext, Dispatch, SetStateAction } from 'react'

export interface RoomContextInterface {
  isEditModalOpen: boolean
  isStartGameModalOpen: boolean
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>
  setIsStartGameModalOpen: Dispatch<SetStateAction<boolean>>
}

export const RoomContextDefaultValue = {
  isEditModalOpen: false,
  isStartGameModalOpen: false,
  setIsEditModalOpen: () => {
    throw new Error('No setIsEditModalOpen Defined')
  },
  setIsStartGameModalOpen: () => {
    throw new Error('No setIsStartGameModalOpen Defined')
  },
}

export const RoomContext = createContext<RoomContextInterface>(
  RoomContextDefaultValue
)
