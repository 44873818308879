import React, { FunctionComponent, ReactNode } from 'react'
import {
  Operation,
  Operator,
  Rule,
  SequenceOperation,
  SequenceRule,
} from 'types/operator'
import CardWithIcon from 'modules/common/cards/cardWithIcon'
import { Device } from 'types/device'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'

type ComponentProps = {
  operator: Operator
}
type Props = ComponentProps & ReturnType<typeof mapStateToProps>

const OperationsGrid: FunctionComponent<Props> = ({
  operator,
  currentRoom,
}) => {
  const getDeviceFromOperation = (operation: Operation): Device | null => {
    if (
      null === currentRoom ||
      typeof currentRoom.devices === 'undefined' ||
      null === currentRoom.devices
    ) {
      return null
    }
    return (
      currentRoom.devices.find(device => device.id === operation.id) || null
    )
  }

  return (
    <>
      {typeof operator.operations !== 'undefined' &&
      operator.operations.length > 0 ? (
        operator.operations.map(operation => {
          const device = getDeviceFromOperation(operation)
          return (
            <CardWithIcon
              key={operation.id}
              title={device?.name || ''}
              value={
                <>
                  {`Operation state: ${operation.state}`}
                  <br />
                  {'device' === operation.type && (
                    <>
                      {`Device state: ${device?.state}`}
                      <br />
                      {`Expected: ${operation.value}`}
                    </>
                  )}
                </>
              }
              type={operation.state ? 'success' : 'danger'}
            />
          )
        })
      ) : (
        <p>No opearations yet, add new ruleset</p>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
})

export default connect(mapStateToProps)(OperationsGrid)
