import React, { FunctionComponent } from 'react'
import { RuleChain } from 'types/operator'
import { FastField } from 'formik'
import SelectField from 'modules/common/form/selectField'
import RulesEdit from 'modules/operator/components/rulesEdit'

type Props = {
  ruleChain?: RuleChain
  index: number
  formNamePrefix: string
}

const RuleChainEdit: FunctionComponent<Props> = ({
  ruleChain,
  index,
  formNamePrefix,
}) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg mb-6">
      <div className="flex justify-between">
        <div className="flex-1 bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                RuleChain {index}
              </h3>
            </div>
            <div>
              <FastField
                component={SelectField}
                name={`${formNamePrefix}.logic`}
                placeholder="Rulechain logic"
              >
                <option value="AND">AND</option>
                <option value="OR">OR</option>
              </FastField>
            </div>
          </div>
        </div>
      </div>
      <RulesEdit
        rules={ruleChain?.rules}
        ruleChainIndex={index}
        formNamePrefix={formNamePrefix}
      />
    </div>
  )
}

export default RuleChainEdit
