import React, { FunctionComponent } from 'react'
import { FieldProps } from 'formik'
import classNames from 'classnames'

const BooleanField: FunctionComponent<FieldProps & { label: string }> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  ...props
}) => {
  return (
    <>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
        <span
          role="checkbox"
          aria-checked="false"
          className={classNames({
            ['relative block mt-2 flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline']: true,
            'bg-indigo-600': !!field.value,
            'bg-gray-200': !field.value,
          })}
        >
          <span
            aria-hidden="true"
            className={classNames({
              ['inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200']: true,
              'translate-x-5': field.value === true,
              'translate-x-0': field.value === false,
            })}
          />
        </span>
      </label>
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
      <input
        type="checkbox"
        id={field.name}
        className="hidden"
        {...field}
        {...props}
      />
    </>
  )
}
export default BooleanField
