import React, { FunctionComponent } from 'react'
import { RoomComponentProps, Room } from 'types/room'
import { RouteComponentProps } from '@reach/router'
import { connect } from 'react-redux'
import { AppState } from 'state/rootReducer'
import EmptyCard from 'modules/common/cards/emptyCard'
import RelayList from 'modules/devices/relaysContainer'
import { RoomContext } from 'modules/room/roomContextProvider'
import RoomContainer from 'modules/room/roomContainer'
import ExtraDevicesEditContainer from 'modules/room/extraDevicesEditContainer'
import ExtraDevicesViewContainer from 'modules/room/extraDevicesViewContainer'
import OperatorsViewContainer from 'modules/operator/operatorsViewContainer'
import OperatorsEditContainer from 'modules/operator/operatorsEditContainer'

const RoomEditor: FunctionComponent<
  RouteComponentProps<RoomComponentProps & ReturnType<typeof mapStateToProps>>
> = ({ roomId, currentRoom, isDesignerMode }) => {
  if (typeof currentRoom === 'undefined') {
    return <></>
  }
  const hasOperators = (room: any): room is Room => {
    return (
      typeof room !== 'undefined' &&
      room !== null &&
      typeof room.operators !== 'undefined' &&
      room.operators !== null
    )
  }

  return (
    <>
      <RoomContainer roomId={roomId}>
        <RoomContext.Consumer>
          {() => (
            <>
              <div className="mb-8">
                {isDesignerMode && <OperatorsEditContainer />}
                {hasOperators(currentRoom) && !isDesignerMode && (
                  <OperatorsViewContainer />
                )}
                {!hasOperators(currentRoom) && !isDesignerMode && (
                  <EmptyCard>
                    <p className="text-lg font-semibold text-center text-gray-700 dark:text-gray-200">
                      No Operators, start designer
                    </p>
                  </EmptyCard>
                )}
              </div>
              <div className="mb-8">
                <RelayList />
              </div>
              <div className="mb-8">
                {isDesignerMode ? (
                  <ExtraDevicesEditContainer />
                ) : (
                  <ExtraDevicesViewContainer />
                )}
              </div>
            </>
          )}
        </RoomContext.Consumer>
      </RoomContainer>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
  isDesignerMode: state.currentRoom.isDesignerMode,
})

export default connect(mapStateToProps)(RoomEditor)
