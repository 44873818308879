import { createContext, ReactNode } from 'react'
import { ModalHandler } from 'modules/common/modal/useModal'

export interface ModalContextInterface {
  modalContent?: ReactNode
  handleModal: ModalHandler
  modal: boolean
}

export const ModalContextDefaultValue = {
  handleModal: (): boolean => {
    return false
  },
  modal: false,
}

export const ModalContext = createContext<ModalContextInterface>(
  ModalContextDefaultValue
)
