import React, { FunctionComponent, useContext } from 'react'
import { DangerModal } from 'modules/common/modal/dangerModal'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { GameTip } from 'types/gameTip'
import { removeTip } from 'modules/gameTip/state/actions'

type Props = {
  gameTip: GameTip
} & ReturnType<typeof mapDispatchToProps>

const RemoveModalContainer: FunctionComponent<Props> = ({
  gameTip,
  removeTip,
}) => {
  const { handleModal } = useContext(ModalContext)

  const removeCallback = async () => {
    await removeTip({ gameTip })
    closeModal()
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <DangerModal
        title={'Remove tip'}
        content={`Are you sure you want to remove tip: "${gameTip.tip || ''}"`}
        confirmButtonText={'Remove'}
        cancelButtonText={'Cancel'}
        cancelCallback={closeModal}
        successCallback={removeCallback}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    removeTip: ({ gameTip }: { gameTip: GameTip }) =>
      dispatch(removeTip({ gameTip })),
  }
}

export default connect(null, mapDispatchToProps)(RemoveModalContainer)
