import React, { FunctionComponent, useEffect, useState } from 'react'
import { Route } from 'types/route'
import { getRoutes } from 'routes/routes'
import { AppState } from 'state/rootReducer'
import { Room } from 'types/room'
import { connect } from 'react-redux'
import TopNavigation from 'modules/navigation/topNavigation'

type Props = ReturnType<typeof mapStateToProps>

const TopNavigationContainer: FunctionComponent<Props> = ({ rooms }) => {
  const [routes, setRoutes] = useState<Route[] | null>(null)
  useEffect(() => {
    const data = getRoutes({ rooms })
    setRoutes(data)
  }, [rooms])

  return <TopNavigation routes={routes} />
}

const mapStateToProps = (state: AppState): { rooms: Room[] } => ({
  rooms: state.rooms.data,
})

export default connect(mapStateToProps)(TopNavigationContainer)
