import React, { FunctionComponent, useContext } from 'react'
import { Room } from 'types/room'
import Link from 'gatsby-link'
import RoomCard from 'modules/room/components/roomCard'
import styles from 'modules/room/components/roomGrid.module.css'
import classNames from 'classnames'
import EmptyCard from 'modules/common/cards/emptyCard'
import { RoomListComponentProps } from 'modules/room/roomGridContainer'
import { ModalContext } from 'modules/common/modal/modalContainer'
import CreateModalContainer from 'modules/room/createModalContainer'

const isRoomGaming = (room: Room): boolean => {
  return (
    typeof room.gameClock !== 'undefined' &&
    typeof room.running !== 'undefined' &&
    room.running > 0
  )
}

const RoomGrid: FunctionComponent<RoomListComponentProps> = ({ rooms }) => {
  const { handleModal } = useContext(ModalContext)

  const openCreateModal = () => {
    handleModal(<CreateModalContainer />)
  }

  return (
    <div className={styles.container}>
      {typeof rooms !== 'undefined' &&
        rooms.length > 0 &&
        rooms.map(room => (
          <RoomCard
            key={room.id}
            roomId={room.id}
            title={room.name}
            gaming={isRoomGaming(room)}
            value={isRoomGaming(room) ? 'Peli käynnissä' : 'Peli keskeytetty'}
          >
            <span
              className={classNames({
                [styles.icon]: true,
                [styles.iconActive]: isRoomGaming(room),
                'animate-pulse': isRoomGaming(room),
              })}
            />
          </RoomCard>
        ))}
      <Link to={`#`} onClick={() => openCreateModal()}>
        <EmptyCard>
          <p className="text-lg font-semibold text-center text-gray-700 dark:text-gray-200">
            <span className={styles.itemInner}>Lisää uusi Huone</span>
          </p>
        </EmptyCard>
      </Link>
    </div>
  )
}

export default RoomGrid
