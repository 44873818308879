import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import { Room } from 'types/room'
import { fetchRequest } from 'modules/room/state/actions'
import StartGameForm from 'modules/room/components/startGameForm'
import { GameClockStart } from 'types/gameClock'
import { startRequest } from 'modules/gameClock/state/actions'

type Props = {
  room: Room
} & ReturnType<typeof mapDispatchToProps>

const StartGameModalContainer: FunctionComponent<Props> = ({
  room,
  startGame,
  fetchCurrentRoom,
}) => {
  const { handleModal } = useContext(ModalContext)

  const handleStartGame = async ({
    gameClock,
  }: {
    gameClock: GameClockStart
  }): Promise<{ gameClock: GameClockStart }> => {
    if (typeof startGame !== 'undefined' && null !== room) {
      await startGame({ roomId: room.id, gameClock })
    }
    if (fetchCurrentRoom && null !== room) {
      await fetchCurrentRoom({ roomId: room.id })
    }
    closeModal()

    return { gameClock }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal title={`Start Game`} cancelCallback={closeModal}>
        <StartGameForm submitCallback={handleStartGame} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchCurrentRoom: ({ roomId }: { roomId: string }) =>
      dispatch(fetchRequest({ roomId })),
    startGame: ({
      roomId,
      gameClock,
    }: {
      roomId: string
      gameClock: GameClockStart
    }) => dispatch(startRequest({ roomId, gameClock })),
  }
}

export default connect(null, mapDispatchToProps)(StartGameModalContainer)
