import { GameClockActionTypes } from 'modules/gameClock/state/types'

import { Action, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { API, Auth } from 'aws-amplify'
import { addMessage } from 'modules/messages/state/actions'
import { GameClockStart } from 'types/gameClock'
import { fetchRequest as fetchRoom } from 'modules/room/state/actions'
import { AppThunk } from 'types/app'
import { fetchListRequest } from 'modules/gameHistory/state/actions'

export const fetchRequest: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameClockActionTypes.FETCH_REQUEST })
    try {
      const result = await API.get('GameClockService', `/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      return dispatch({
        type: GameClockActionTypes.FETCH_SUCCESS,
        payload: result,
      })
    } catch (e) {
      return dispatch({
        type: GameClockActionTypes.FETCH_ERROR,
      })
    }
  }
}

export const startRequest: AppThunk<{
  roomId: string
  gameClock: GameClockStart
}> = ({ roomId, gameClock }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameClockActionTypes.START_REQUEST })
    try {
      await API.post('GameClockService', `/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: gameClock,
      })
      await dispatch(fetchRoom({ roomId }))
      return dispatch({
        type: GameClockActionTypes.START_SUCCESS,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: `Cannot start Game`,
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: GameClockActionTypes.START_ERROR,
      })
    }
  }
}

export const stopRequest: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameClockActionTypes.STOP_REQUEST })
    try {
      await API.put('GameClockService', `/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          event: 'stop',
        },
      })
      await dispatch(fetchRoom({ roomId }))
      await dispatch(fetchListRequest({ roomId }))
      return dispatch({
        type: GameClockActionTypes.STOP_SUCCESS,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: `Cannot stop Game`,
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: GameClockActionTypes.STOP_ERROR,
      })
    }
  }
}

export const pauseRequest: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameClockActionTypes.PAUSE_REQUEST })
    try {
      await API.put('GameClockService', `/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          event: 'pause',
        },
      })
      await dispatch(fetchRoom({ roomId }))
      return dispatch({
        type: GameClockActionTypes.PAUSE_SUCCESS,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: `Cannot pause Game`,
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: GameClockActionTypes.PAUSE_ERROR,
      })
    }
  }
}

export const resumeRequest: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameClockActionTypes.RESUME_REQUEST })
    try {
      await API.put('GameClockService', `/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          event: 'resume',
        },
      })
      await dispatch(fetchRoom({ roomId }))
      return dispatch({
        type: GameClockActionTypes.RESUME_SUCCESS,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: `Cannot resume Game`,
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: GameClockActionTypes.RESUME_ERROR,
      })
    }
  }
}
