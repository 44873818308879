import React, { FunctionComponent, useEffect, useState } from 'react'
import { withRoom } from 'modules/room/withRoom'
import PageTitle from 'modules/common/typography/pageTitleWithActions'
import RoomButtons from 'modules/room/roomButtonsContainer'
import { RoomTabs } from 'modules/room/components/roomTabs'
import GameParticipants from 'modules/room/components/gameParticipants'
import RoomBottom from 'modules/room/components/roomBottom'
import GameClockContainer from 'modules/gameClock/gameClockContainer'
import GiveTipContainer from 'modules/gameTip/giveTipContainer'
import { RoomComponentProps } from 'types/room'
import { AppState } from 'state/rootReducer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { fetchRequest } from 'modules/room/state/actions'
import { AppMessage } from 'modules/messages/state/types'
import { addMessage } from 'modules/messages/state/actions'
import { connect } from 'react-redux'

type Props = RoomComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const RoomContainer: FunctionComponent<Props> = ({
  children,
  roomId,
  currentRoom,
  fetchCurrentRoom,
  isPolling,
}) => {
  const [poller, setPoller] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (typeof roomId !== 'undefined') {
      if (fetchCurrentRoom) {
        fetchCurrentRoom({ roomId })
      }
    }
  }, [roomId])
  useEffect(() => {
    if (typeof roomId !== 'undefined') {
      if (isPolling) {
        setPoller(setInterval(() => fetchCurrentRoom({ roomId }), 3000))
      } else {
        if (null !== poller) {
          clearInterval(poller)
        }
      }
    }
  }, [isPolling])

  const isRoomRunning =
    null !== currentRoom &&
    typeof currentRoom.running !== 'undefined' &&
    currentRoom.running > 0

  return (
    <>
      {currentRoom && (
        <>
          <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none pt-2 py-6 mb-12">
            <PageTitle title={currentRoom.name}>
              <RoomButtons />
            </PageTitle>
            <RoomTabs room={currentRoom} />
            {children}

            {typeof currentRoom.gameClock !== 'undefined' && isRoomRunning && (
              <>
                <GameParticipants
                  participants={currentRoom.gameClock.participants}
                />
                <RoomBottom>
                  <GameClockContainer />
                  <div />
                  <GiveTipContainer />
                </RoomBottom>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
  user: state.user,
  isDesignerMode: state.currentRoom.isDesignerMode,
  isPolling: state.currentRoom.isPolling,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchCurrentRoom: ({ roomId }: { roomId: string }) =>
      dispatch(fetchRequest({ roomId })),
    addMessage: ({ message }: { message: AppMessage }) =>
      dispatch(addMessage({ message })),
  }
}

export default withRoom(
  connect(mapStateToProps, mapDispatchToProps)(RoomContainer)
)
