import React, { FunctionComponent } from 'react'

// import ChartCard from 'components/chart/chartCard'
// import { Line } from 'react-chartjs-2'
// import ChartLegend from 'components/chart/chartLegend'
// import { lineOptions, lineLegends } from 'utils/demo/chartsData'
import PageTitle from 'modules/common/typography/pageTitle'

import { RouteComponentProps } from '@reach/router'
import RoomGridContainer from 'modules/room/roomGridContainer'

const DashboardContainer: FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <PageTitle>Dashboard</PageTitle>

      <RoomGridContainer />

      {/*
      <PageTitle>Pelejä käynnistetty</PageTitle>
      <ChartCard title="Lines">
        <Line {...lineOptions} />
        <ChartLegend legends={lineLegends} />
      </ChartCard>
      */}
    </>
  )
}

export default DashboardContainer
