import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { CommonModal } from 'modules/common/modal/commonModal'
import RegisterForm from 'modules/devices/components/registerForm'
import { navigate } from '@reach/router'

const RegisterModalContainer: FunctionComponent = () => {
  const { handleModal } = useContext(ModalContext)

  const registerCallback = async ({
    deviceId,
  }: {
    deviceId: string
  }): Promise<{ deviceId: string }> => {
    return new Promise(resolve => {
      navigate(`/app/devices/register/${deviceId}`).then(() => {
        closeModal()
        resolve({ deviceId })
      })
    })
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal title={`Add new device`} cancelCallback={closeModal}>
        <RegisterForm submitCallback={registerCallback} />
      </CommonModal>
    </>
  )
}

export default RegisterModalContainer
