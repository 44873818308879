import { ReactNode, useState } from 'react'
import { RoomContextInterface } from './roomContext'

export type RoomHandler = (content: ReactNode) => void

export const useRoomContext = (): RoomContextInterface => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isStartGameModalOpen, setIsStartGameModalOpen] = useState(false)

  return {
    isEditModalOpen,
    isStartGameModalOpen,
    setIsEditModalOpen,
    setIsStartGameModalOpen,
  }
}
