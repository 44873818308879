import React, { FunctionComponent } from 'react'

import PageTitle from 'modules/common/typography/pageTitle'

import { RouteComponentProps } from '@reach/router'
import RoomGridContainer from 'modules/room/roomGridContainer'

const RoomsContainer: FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <PageTitle>Rooms</PageTitle>

      <RoomGridContainer />
    </>
  )
}

export default RoomsContainer
