import React, { FunctionComponent } from 'react'
import { FaStop, FaPlay } from 'react-icons/fa'
import CardWithIcon from 'modules/common/cards/cardWithIcon'

type Props = {
  title: string
  value: string
  roomId: string
  gaming: boolean
}

const RoomCard: FunctionComponent<Props> = ({
  title,
  value,
  roomId,
  gaming,
}) => {
  return (
    <CardWithIcon
      type={gaming ? 'success' : 'danger'}
      icon={gaming ? FaPlay : FaStop}
      link={`/app/rooms/${roomId}/`}
      title={title}
      value={value}
    />
  )
}

export default RoomCard
