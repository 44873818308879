import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { FaBars, FaUserCircle, FaSignOutAlt } from 'react-icons/fa'
import { Dropdown, DropdownItem } from '@windmill/react-ui'
import LogoLight from 'assets/img/getaway-logo.png'
import { sidebarToggle } from 'modules/theme/actions'
import styles from 'components/header.module.css'
import classNames from 'classnames'
import { Auth } from 'aws-amplify'
import { AppState } from 'state/rootReducer'
import TopNavigationContainer from 'modules/navigation/topNavigationContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

type HeaderProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Header: FunctionComponent<HeaderProps> = ({ toggleSidebar }) => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  const handleProfileClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen)
  }

  return (
    <header className={styles.headerWrapper}>
      <div className={classNames('container', styles.headerContainer)}>
        <button
          className={styles.hamburgerButton}
          aria-label="Menu"
          onClick={() => toggleSidebar()}
        >
          <FaBars className="w-6 h-6" aria-hidden="true" />
        </button>

        <img src={LogoLight} alt="Getaway Logo" />
        <TopNavigationContainer />
        <ul className="flex items-center flex-shrink-0 space-x-6">
          <li className="relative">
            <button
              className="rounded-full focus:shadow-outline-purple focus:outline-none"
              onClick={() => handleProfileClick()}
              aria-label="Account"
              aria-haspopup="true"
            >
              <FaUserCircle className="w-6 h-6" aria-hidden="true" />
            </button>
            <Dropdown
              align="right"
              isOpen={isProfileMenuOpen}
              onClose={() => console.log('close')}
            >
              <DropdownItem onClick={() => Auth.signOut()}>
                <FaSignOutAlt className="w-4 h-4 mr-3" aria-hidden="true" />
                <span>Log out</span>
              </DropdownItem>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  )
}
const mapStateToProps = (state: AppState) => {
  return {
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    toggleSidebar: () => dispatch(sidebarToggle()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
