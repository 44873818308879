import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import { GameTip } from 'types/gameTip'
import { createTip } from 'modules/gameTip/state/actions'
import CreateForm from 'modules/gameTip/components/createForm'

type Props = ReturnType<typeof mapDispatchToProps>

const CreateModalContainer: FunctionComponent<Props> = ({ addGameTip }) => {
  const { handleModal } = useContext(ModalContext)

  const createCallback = async ({ gameTip }: { gameTip: GameTip }) => {
    await addGameTip({ gameTip })
    closeModal()
    return { gameTip }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal title={`Create Tip`} cancelCallback={closeModal}>
        <CreateForm submitCallback={createCallback} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    addGameTip: ({ gameTip }: { gameTip: GameTip }) =>
      dispatch(createTip({ gameTip })),
  }
}

export default connect(null, mapDispatchToProps)(CreateModalContainer)
