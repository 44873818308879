import React, { FunctionComponent, ReactNode } from 'react'

type ModalProps = {
  title: string
  content: ReactNode
  confirmButtonText: string
  cancelButtonText: string
  cancelCallback: () => any
  successCallback: () => any
}

export const DangerModal: FunctionComponent<ModalProps> = ({
  title,
  content,
  confirmButtonText,
  cancelButtonText,
  cancelCallback,
  successCallback,
}) => {
  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg
            className="h-6 w-6 text-red-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            {title}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{content}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
        <button
          onClick={successCallback}
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
        >
          {confirmButtonText}
        </button>
        <button
          onClick={cancelCallback}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {cancelButtonText}
        </button>
      </div>
    </>
  )
}
