import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import Button from 'modules/common/buttons/button'
import { AppMessage } from 'modules/messages/state/types'
import { addMessage } from 'modules/messages/state/actions'
import { Device } from 'types/device'
import { RelayView } from 'modules/devices/components/relayView'
import { Relay } from 'types/relay'
import { editRelaysRequest } from 'modules/devices/state/actions'
import RelayEdit from 'modules/devices/components/relayEdit'
import { TableHeadCell, TableRowCell } from 'modules/common/table/table'
import { CardHeader } from 'modules/common/cards/cardHeader'

const emptyRelay: Relay = { source: '', target: '' }

const RelaysContainer: FunctionComponent<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ currentRoom, isDesignerMode, devices, editRelays }) => {
  const relays: Relay[] | any = currentRoom?.devices
    ?.filter(device => typeof device.relayTarget !== 'undefined')
    .map(source => {
      const relayDevice = devices?.find(
        device => device.id === source.relayTarget
      )
      if (relayDevice) {
        return {
          source: source.id,
          target: relayDevice.id,
        }
      }
    })

  const getSourceDevice = ({ relay }: { relay: Relay }): Device | undefined => {
    return devices?.find(device => device.id === relay?.source)
  }

  const getTargetDevice = ({ relay }: { relay: Relay }): Device | undefined => {
    return devices?.find(device => device.id === relay?.target)
  }

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg my-4">
      <CardHeader>Relays</CardHeader>
      <div className="px-4 py-5 sm:p-6">
        <Formik
          initialValues={{
            relays: relays || [emptyRelay],
          }}
          onSubmit={(
            values: { relays: Relay[] },
            {
              setSubmitting,
            }: FormikHelpers<{
              relays: Relay[]
            }>
          ) => {
            setTimeout(() => {
              console.table(values)
              if (typeof currentRoom !== 'undefined' && null !== currentRoom) {
                editRelays({ relays: values.relays })
              }
              setSubmitting(false)
            }, 500)
          }}
        >
          {({ values }) => (
            <Form>
              <table className="min-w-full w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <TableHeadCell>Source</TableHeadCell>
                    <TableHeadCell>Destination</TableHeadCell>
                    {isDesignerMode && <TableHeadCell>Actions</TableHeadCell>}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name={`relays`}>
                    {({ remove, push }) => (
                      <>
                        {values.relays?.map((relay, index) => {
                          if (isDesignerMode) {
                            return (
                              <RelayEdit
                                key={`relays.${index}`}
                                index={index}
                                removeCallback={remove}
                              />
                            )
                          } else {
                            return (
                              <RelayView
                                key={`relays.${index}`}
                                sourceDevice={getSourceDevice({ relay })}
                                targetDevice={getTargetDevice({ relay })}
                              />
                            )
                          }
                        })}
                        {isDesignerMode && (
                          <tr className="bg-white">
                            <TableRowCell
                              className="font-medium text-gray-900 border-dashed border-t text-center"
                              colSpan={5}
                            >
                              <Button
                                type="button"
                                onClick={() => push({ source: '', target: '' })}
                              >
                                Add new relay
                              </Button>
                            </TableRowCell>
                          </tr>
                        )}
                      </>
                    )}
                  </FieldArray>
                </tbody>
              </table>
              {isDesignerMode && <Button type="submit">Save </Button>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
  currentRoom: state.currentRoom.data,
  isDesignerMode: state.currentRoom.isDesignerMode,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    addMessage: ({ message }: { message: AppMessage }) =>
      dispatch(addMessage({ message })),
    editRelays: ({ relays }: { relays: Relay[] }) =>
      dispatch(editRelaysRequest({ relays })),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RelaysContainer)
