import { GameHistoryActionTypes } from './types'

import { Action, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { API, Auth } from 'aws-amplify'
import { addMessage } from 'modules/messages/state/actions'
import { AppThunk } from 'types/app'

export const fetchListRequest: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameHistoryActionTypes.FETCH_LIST_REQUEST })
    try {
      const result = await API.get('GameClockService', `/room/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      return dispatch({
        type: GameHistoryActionTypes.FETCH_LIST_SUCCESS,
        payload: result.gameClocks,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            body: 'Cannot load Game history',
            type: 'danger',
          },
        })
      )
      return dispatch({
        type: GameHistoryActionTypes.FETCH_LIST_ERROR,
      })
    }
  }
}
