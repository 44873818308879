import React, { FunctionComponent } from 'react'

const EmptyCard: FunctionComponent = ({ children }) => {
  return (
    <div className="col-span-1 flex border-dashed border rounded-md justify-center px-4 py-4 bg-white">
      {children}
    </div>
  )
}

export default EmptyCard
