import { ReactNode, useState } from 'react'
import { ModalContextInterface } from 'modules/common/modal/modalContext'

export type ModalHandler = (content: ReactNode) => void

export const useModal = (): ModalContextInterface => {
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode>()

  const handleModal: ModalHandler = (content = '') => {
    setModal(!modal)
    if (content) {
      setModalContent(content)
    }
  }

  return { modal, handleModal, modalContent }
}
