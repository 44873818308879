import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { AppMessage } from 'modules/messages/state/types'
import MessageContainer from 'modules/messages/messageContainer'
type Props = {
  messages: AppMessage[]
} & ReturnType<typeof mapStateToProps>
const MessagesListContainer: FunctionComponent<Props> = ({ messages }) => {
  return (
    <div className="my-6">
      {messages.map(message => (
        <MessageContainer message={message} key={message.id} />
      ))}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  messages: state.messages.data,
})

export default connect(mapStateToProps)(MessagesListContainer)
