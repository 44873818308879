import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { Operator, Rule, SequenceRule } from 'types/operator'
import { Device } from 'types/device'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import CardWithIcon from 'modules/common/cards/cardWithIcon'

type ComponentProps = {
  operator: Operator
}
type Props = ComponentProps & ReturnType<typeof mapStateToProps>

const SensorsContainer: FunctionComponent<Props> = ({
  operator,
  currentRoom,
}) => {
  const [rules, setRules] = useState<Rule[]>([])
  useEffect(() => {
    if (typeof operator.ruleChains !== 'undefined') {
      setRules(
        operator.ruleChains.flatMap(ruleChain => {
          return ruleChain.rules
        })
      )
    }
  }, [operator])

  if (typeof operator === 'undefined') {
    return <></>
  }
  const getDeviceFromRule = (rule: Rule): Device | Operator | null => {
    if (
      null === currentRoom ||
      typeof currentRoom.devices === 'undefined' ||
      typeof currentRoom.operators === 'undefined' ||
      null === currentRoom.devices ||
      null === currentRoom.operators
    ) {
      return null
    }
    return currentRoom.devices.find(device => device.id === rule.id) || null
  }
  return (
    <>
      {rules.length > 0 ? (
        rules.map(rule => {
          const device = getDeviceFromRule(rule)
          return (
            <div key={rule.id}>
              <CardWithIcon
                title={null !== device ? device.name : rule.id}
                value={
                  <>
                    {`Rule State: ${rule.state}`} <br />
                    {'device' === rule.type && (
                      <>
                        {`Device State: ${device?.state}`}
                        <br />
                        {`Expected: ${rule.value}`} <br />
                      </>
                    )}
                  </>
                }
                type={rule.state ? 'success' : 'danger'}
              />
            </div>
          )
        })
      ) : (
        <p>No Sensors yet, add new ruleset</p>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
})

export default connect(mapStateToProps)(SensorsContainer)
