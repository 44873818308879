import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import { RoomInit } from 'types/room'
import { createRequest } from 'modules/room/state/actions'
import CreateForm from 'modules/room/components/createForm'

type Props = ReturnType<typeof mapDispatchToProps>

const CreateModalContainer: FunctionComponent<Props> = ({ createRoom }) => {
  const { handleModal } = useContext(ModalContext)

  const createCallback = async ({ room }: { room: RoomInit }) => {
    await createRoom({ room })
    closeModal()
    return { room }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal title={`Create Room`} cancelCallback={closeModal}>
        <CreateForm submitCallback={createCallback} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    createRoom: ({ room }: { room: RoomInit }) =>
      dispatch(createRequest({ room })),
  }
}

export default connect(null, mapDispatchToProps)(CreateModalContainer)
