import React, { FunctionComponent } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import Button from 'modules/common/buttons/button'
import TextField from 'modules/common/form/textField'

interface NewDeviceValues {
  deviceId: string
}
type SubmitCallback = (p: { deviceId: string }) => Promise<NewDeviceValues>

type RegisterForm = {
  submitCallback: SubmitCallback
}

const RegisterForm: FunctionComponent<RegisterForm> = ({ submitCallback }) => {
  return (
    <div>
      <Formik
        initialValues={{ deviceId: '' }}
        onSubmit={(
          values: NewDeviceValues,
          { setSubmitting }: FormikHelpers<NewDeviceValues>
        ) => {
          submitCallback({ deviceId: values.deviceId }).then(() =>
            setSubmitting(false)
          )
        }}
      >
        {() => (
          <Form>
            <Field
              component={TextField}
              label="Device ID"
              id="deviceId"
              name="deviceId"
              placeholder="Device ID"
            />

            <Button className="w-full sm:w-auto mt-6" type="submit">
              Register
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default RegisterForm
