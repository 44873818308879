import React, { FunctionComponent } from 'react'
import { AppMessage } from 'modules/messages/state/types'
import classNames from 'classnames'

type Props = {
  message: AppMessage
  remove: ({ message }: { message: AppMessage }) => any
}

const Message: FunctionComponent<Props> = ({ message, remove }) => {
  return (
    <div
      className={classNames({
        ['rounded-md p-4']: true,
        'bg-green-50': message.type === 'success',
        'bg-red-50': message.type === 'danger',
      })}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className={classNames({
              ['h-5 w-5']: true,
              'text-green-400': message.type === 'success',
              'text-red-400': message.type === 'danger',
            })}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p
            className={classNames({
              ['text-sm leading-5 font-medium']: true,
              'text-green-800': message.type === 'success',
              'text-red-800': message.type === 'danger',
            })}
          >
            {message.body}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              className={classNames({
                ['inline-flex rounded-md p-1.5 focus:outline-none transition ease-in-out duration-150']: true,
                'text-green-500 hover:bg-green-100 focus:bg-green-100':
                  message.type === 'success',
                'text-red-500 hover:bg-red-100 focus:bg-red-100':
                  message.type === 'danger',
              })}
              aria-label="Dismiss"
              onClick={() => remove({ message })}
            >
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message
