import React, { FunctionComponent } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import { Room } from 'types/room'
import Button from 'modules/common/buttons/button'
import TextField from 'modules/common/form/textField'

interface RoomValues {
  name: string
}
type SubmitCallback = (p: { room: Room }) => Promise<{ room: Room }>

type EditForm = {
  room: Room
  submitCallback: SubmitCallback
}

const EditForm: FunctionComponent<EditForm> = ({ room, submitCallback }) => {
  const { name } = room

  return (
    <div>
      <Formik
        initialValues={{ name }}
        onSubmit={(
          values: RoomValues,
          { setSubmitting }: FormikHelpers<RoomValues>
        ) => {
          submitCallback({ room: { id: room.id, ...values } }).then(() => {
            setSubmitting(false)
          })
        }}
      >
        <Form>
          <Field
            component={TextField}
            label="Name"
            id="name"
            name="name"
            placeholder="Room Name"
          />

          <Button className="w-full sm:w-auto mt-6" type="submit">
            Save
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
export default EditForm
