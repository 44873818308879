import React, { FunctionComponent } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import { Device, DeviceLibrary } from 'types/device'
import Button from 'modules/common/buttons/button'
import SelectField from 'modules/common/form/selectField'
import TextField from 'modules/common/form/textField'
import BooleanField from 'modules/common/form/booleanField'

interface DeviceValues {
  name: string
  library: DeviceLibrary
  state: string | boolean | number | null
}
type SubmitCallback = (p: { device: Device }) => Promise<{ device: Device }>

type EditForm = {
  device: Device
  submitCallback: SubmitCallback
}

const EditForm: FunctionComponent<EditForm> = ({ device, submitCallback }) => {
  const { name, library, state } = device
  const stateValue = typeof state !== 'undefined' ? state : null

  return (
    <div>
      <Formik
        initialValues={{ name, library, state: stateValue }}
        onSubmit={(
          values: DeviceValues,
          { setSubmitting }: FormikHelpers<DeviceValues>
        ) => {
          submitCallback({ device: { id: device.id, ...values } }).then(() => {
            setSubmitting(false)
          })
        }}
      >
        {({ values }) => (
          <Form>
            <Field
              component={TextField}
              label="Name"
              id="name"
              name="name"
              placeholder="Device Name"
            />

            <Field
              component={SelectField}
              name="library"
              disabled
              label="Library"
            >
              <option>No library</option>
              <option value="sequence">Sequence</option>
              <option value="gameClock">Game Clock</option>
              <option value="boolean">Boolean</option>
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="tip">Tip</option>
            </Field>
            {values.library === 'boolean' ? (
              <>
                <Field
                  component={BooleanField}
                  label="State"
                  id="state"
                  name="state"
                />
              </>
            ) : (
              <>
                <Field
                  component={TextField}
                  label="State"
                  id="state"
                  name="state"
                  placeholder=""
                />
              </>
            )}

            <Button className="w-full sm:w-auto mt-6" type="submit">
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default EditForm
