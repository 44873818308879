import React, { FunctionComponent } from 'react'
import { Device } from 'types/device'
import { TableRowCell } from 'modules/common/table/table'

type Props = {
  device?: Device
  targetDevice?: Device
}

export const ExtraDeviceView: FunctionComponent<Props> = ({ device }) => {
  return (
    <tr className="bg-white">
      <TableRowCell className="text-gray-500">
        {typeof device !== 'undefined' && device.name}
      </TableRowCell>
    </tr>
  )
}
