import React, { FunctionComponent } from 'react'
import { Formik, Field, Form, FormikHelpers, FieldArray } from 'formik'
import Button from 'modules/common/buttons/button'
import { GameClockStart } from 'types/gameClock'
import { FaTrash } from 'react-icons/fa'
import { TableHeadCell, TableRowCell } from 'modules/common/table/table'
import BooleanField from 'modules/common/form/booleanField'

type SubmitCallback = (p: {
  gameClock: GameClockStart
}) => Promise<{ gameClock: GameClockStart }>

type StartGameForm = {
  submitCallback: SubmitCallback
}

const StartGameForm: FunctionComponent<StartGameForm> = ({
  submitCallback,
}) => {
  const duration = 60
  const participants: string[] = ['']

  return (
    <Formik
      initialValues={{ duration, participants, paused: false }}
      onSubmit={(
        values: GameClockStart,
        { setSubmitting }: FormikHelpers<GameClockStart>
      ) => {
        submitCallback({
          gameClock: { ...values, duration: values.duration * 60000 },
        }).then(() => {
          setSubmitting(false)
        })
      }}
    >
      {({ values }) => (
        <Form>
          <div className="mt-4">
            <label
              htmlFor="duration"
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            >
              Duration (minutes)
            </label>
            <Field
              id="duration"
              name="duration"
              placeholder="Duration (minutes)"
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            />
          </div>
          <div className="mt-4">
            <label
              htmlFor="duration"
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            >
              Start game as paused
            </label>
            <Field id="paused" name="paused" component={BooleanField} />
          </div>
          <table className="min-w-full w-full divide-y divide-gray-200 mt-6">
            <thead>
              <tr>
                <TableHeadCell>Participants</TableHeadCell>
                <TableHeadCell>Actions</TableHeadCell>
              </tr>
            </thead>
            <tbody>
              <FieldArray name="participants">
                {({ remove, push }) => (
                  <>
                    {values.participants && values.participants.length > 0 ? (
                      <>
                        {values.participants.map((participant, index) => (
                          <>
                            <tr className="bg-white">
                              <td className="whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 border-dashed border-t">
                                <Field
                                  id={`participants.${index}`}
                                  name={`participants.${index}`}
                                  placeholder={`Participant ${index + 1} name`}
                                  className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                              </td>
                              <TableRowCell className="font-medium text-gray-900 border-dashed border-t">
                                <FaTrash onClick={() => remove(index)} />
                              </TableRowCell>
                            </tr>
                          </>
                        ))}
                        <tr className="bg-white">
                          <TableRowCell
                            className="font-medium text-gray-900 border-dashed border-t text-center"
                            colSpan={2}
                          >
                            <Button type="button" onClick={() => push('')}>
                              Add participant
                            </Button>
                          </TableRowCell>
                        </tr>
                      </>
                    ) : (
                      <tr className="bg-white">
                        <TableRowCell
                          className="font-medium text-gray-900 border-dashed border-t text-center"
                          colSpan={2}
                        >
                          <Button type="button" onClick={() => push('')}>
                            Add participant
                          </Button>
                        </TableRowCell>
                      </tr>
                    )}
                  </>
                )}
              </FieldArray>
            </tbody>
          </table>
          <Button className="w-full sm:w-auto mt-6" type="submit">
            Start Game
          </Button>
        </Form>
      )}
    </Formik>
  )
}
export default StartGameForm
