import React, { FunctionComponent, useContext } from 'react'
import { connect } from 'react-redux'
import { AppState } from 'state/rootReducer'
import Button from 'modules/common/buttons/button'
import { ModalContext } from 'modules/common/modal/modalContainer'
import EditModalContainer from 'modules/gameTip/editModalContainer'
import { GameTip } from 'types/gameTip'
import CreateModalContainer from 'modules/gameTip/createModalContainer'
import GiveTipModalContainer from 'modules/gameTip/giveTipModalContainer'
import { TableRowCell } from 'modules/common/table/table'
import RemoveModalContainer from 'modules/gameTip/removeModalContainer'

type Props = ReturnType<typeof mapStateToProps>

const RoomGameTipContainer: FunctionComponent<Props> = ({
  gameTips,
  gameClock,
}) => {
  const { handleModal } = useContext(ModalContext)

  const editTipModal = ({ gameTip }: { gameTip: GameTip }) => {
    handleModal(<EditModalContainer gameTip={gameTip} />)
  }
  const createTipModal = () => {
    handleModal(<CreateModalContainer />)
  }
  const removeTipModal = ({ gameTip }: { gameTip: GameTip }) => {
    handleModal(<RemoveModalContainer gameTip={gameTip} />)
  }
  const sendTip = ({ gameTip }: { gameTip: GameTip }) => {
    handleModal(<GiveTipModalContainer gameTip={gameTip} />)
  }
  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Tip
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {gameTips?.map(gameTip => (
            <tr key={gameTip.id || gameTip.tip}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {gameTip.tip}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                {null !== gameClock && (
                  <span
                    className="text-indigo-500 cursor-pointer"
                    onClick={() => sendTip({ gameTip })}
                  >
                    Send tip to room
                  </span>
                )}

                <span
                  className="ml-4 text-indigo-500 cursor-pointer"
                  onClick={() => editTipModal({ gameTip })}
                >
                  Edit
                </span>
                <span
                  className="ml-4 text-red-500 cursor-pointer"
                  onClick={() => removeTipModal({ gameTip })}
                >
                  Remove
                </span>
              </td>
            </tr>
          ))}
          <tr>
            <TableRowCell
              colSpan={2}
              className="font-medium text-gray-900 border-dashed border-t text-center"
            >
              <Button type="button" onClick={() => createTipModal()}>
                Add new Tip
              </Button>
            </TableRowCell>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    gameTips: state.gameTip.data,
    gameClock: state.gameClock.data,
  }
}

export default connect(mapStateToProps)(RoomGameTipContainer)
