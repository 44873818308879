import React, { ElementType, FunctionComponent } from 'react'
import classNames from 'classnames'

type Props = {
  icon?: ElementType
  className?: any
  onClick?: () => any
  type?: 'submit' | 'button'
}
const Button: FunctionComponent<Props> = ({
  onClick,
  className,
  children,
  type,
  icon,
}) => {
  const Icon: FunctionComponent = () => {
    if (typeof icon === 'undefined') {
      return null
    }
    const IconElement: ElementType = icon
    return <IconElement className="-ml-0.5 mr-2 h-4 w-4" />
  }

  return (
    <span
      className={classNames([className, 'inline-flex rounded-md shadow-sm'])}
    >
      <button
        onClick={onClick}
        type={typeof type !== 'undefined' ? type : 'button'}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
      >
        <Icon />
        {children}
      </button>
    </span>
  )
}

export default Button
