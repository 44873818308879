import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { Room, RoomInit } from 'types/room'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { createRequest, fetchRoomsList } from 'modules/room/state/actions'
import { AppState } from 'state/rootReducer'
import RoomGrid from 'modules/room/components/roomGrid'

export interface RoomListComponentProps {
  rooms?: Array<Room>
  createRoom: ({ room }: { room: RoomInit }) => any
}
type Props = RoomListComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const RoomGridContainer: FunctionComponent<Props> = ({
  rooms,
  createRoom,
  fetchRooms,
}) => {
  useEffect(() => {
    if (fetchRooms) {
      fetchRooms()
    }
  }, [])
  return <RoomGrid createRoom={createRoom} rooms={rooms} />
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    createRoom: ({ room }: { room: RoomInit }) =>
      dispatch(createRequest({ room })),
    fetchRooms: () => dispatch(fetchRoomsList()),
  }
}

const mapStateToProps = (state: AppState): { rooms: Room[] } => ({
  rooms: state.rooms.data,
})

export default connect(mapStateToProps, mapDispatchToProps)(RoomGridContainer)
