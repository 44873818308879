import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { Operator } from 'types/operator'

type Props = {
  operators?: Operator[]
}

const OperatorDropdownContainer: FunctionComponent<Props> = ({ operators }) => {
  return (
    <>
      <option>Select Operator</option>
      {operators?.map(operator => (
        <option key={operator.id} value={operator.id}>
          {operator.name}
        </option>
      ))}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  operators: state.currentRoom.data?.operators,
})

export default connect(mapStateToProps)(OperatorDropdownContainer)
