import React, { FunctionComponent, useEffect, useState } from 'react'
import GameClock from 'modules/gameClock/components/gameClock'
import { AppState } from 'state/rootReducer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { pauseRequest, resumeRequest } from 'modules/gameClock/state/actions'

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const GameClockContainer: FunctionComponent<Props> = ({
  roomId,
  gameClock,
  pauseGameClock,
  resumeGameClock,
}) => {
  const [gameClockMinutes, setGameClockMinutes] = useState<number | null>(null)

  useEffect(() => {
    if (null !== gameClock && typeof gameClock.endTime !== 'undefined') {
      if (gameClock.paused) {
        setGameClockMinutes((gameClock.endTime - gameClock.pausedAt) / 60000)
      } else {
        setGameClockMinutes((gameClock.endTime - Date.now()) / 60000)
      }
    }
  }, [gameClock, roomId])
  if (typeof gameClock === 'undefined' || null === gameClock) {
    return <></>
  }
  const pauseCallback = () => {
    if (typeof pauseGameClock !== 'undefined') {
      pauseGameClock({ roomId })
    }
  }
  const resumeCallback = () => {
    if (typeof resumeGameClock !== 'undefined') {
      resumeGameClock({ roomId })
    }
  }

  return (
    <>
      {null !== gameClockMinutes && (
        <GameClock
          countDownMinutes={gameClockMinutes}
          pauseCallback={pauseCallback}
          resumeCallback={resumeCallback}
          paused={gameClock.paused}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  gameClock: state.gameClock.data,
  roomId: null !== state.currentRoom.data ? state.currentRoom.data.id : '',
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    pauseGameClock: ({ roomId }: { roomId: string }) =>
      dispatch(pauseRequest({ roomId })),
    resumeGameClock: ({ roomId }: { roomId: string }) =>
      dispatch(resumeRequest({ roomId })),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GameClockContainer)
