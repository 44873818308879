import React, { FunctionComponent } from 'react'
import styles from 'layouts/fullWidthLayout.module.css'
import Sidebar from 'components/sidebar'
import Header from 'components/header'
import MessageListContainer from 'modules/messages/messagesListContainer'
import { withModal } from 'modules/common/modal/withModal'

const FullWidthLayout: FunctionComponent = ({ children }) => {
  return (
    <div>
      <div className={styles.wrapper}>
        <Sidebar />
        <div className={styles.innerContainer}>
          <Header />
          <main className={styles.main}>
            <div className="container grid px-6 mx-auto">
              <MessageListContainer />
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default withModal(FullWidthLayout)
