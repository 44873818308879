import React, { FunctionComponent, useEffect } from 'react'
import { RoomComponentProps } from 'types/room'
import { RouteComponentProps } from '@reach/router'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import RoomGameTipContainer from 'modules/gameTip/roomGameTipContainer'
import { fetchRoomTips } from 'modules/gameTip/state/actions'
import RoomContainer from 'modules/room/roomContainer'

const RoomTips: FunctionComponent<
  RouteComponentProps<
    RoomComponentProps & ReturnType<typeof mapDispatchToProps>
  >
> = ({ roomId, fetchRoomTips }) => {
  useEffect(() => {
    if (typeof roomId !== 'undefined') {
      if (fetchRoomTips) {
        fetchRoomTips({ roomId })
      }
    }
  }, [roomId])

  return (
    <>
      <RoomContainer roomId={roomId}>
        <div className="mb-8">
          <RoomGameTipContainer />
        </div>
      </RoomContainer>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRoomTips: ({ roomId }: { roomId: string }) =>
      dispatch(fetchRoomTips({ roomId })),
  }
}

export default connect(null, mapDispatchToProps)(RoomTips)
