import React, { FunctionComponent, useContext, useEffect } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { giveTipRequest } from 'modules/devices/state/actions'
import { GameTip } from 'types/gameTip'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { CommonModal } from 'modules/common/modal/commonModal'
import GiveTipForm from 'modules/gameTip/components/giveTipForm'
import TipSelectField from 'modules/gameTip/components/tipSelectField'
import { Device } from 'types/device'
import { fetchRoomTips } from 'modules/gameTip/state/actions'

type Props = {
  gameTip?: GameTip
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const GiveTipModalContainer: FunctionComponent<Props> = ({
  currentRoom,
  giveTip,
  gameTip,
  tips,
  tipDevices,
  fetchRoomTips,
}) => {
  const { handleModal } = useContext(ModalContext)

  useEffect(() => {
    if (typeof currentRoom?.id !== 'undefined') {
      if (fetchRoomTips) {
        fetchRoomTips({ roomId: currentRoom.id })
      }
    }
  }, [currentRoom])

  const closeModal = () => {
    handleModal('')
  }
  const submitTip = async ({ tip }: { tip: string }): Promise<string> => {
    try {
      await tipDevices.map(async tipDevice => {
        await giveTip({ tip, device: tipDevice })
      })
      closeModal()
      return 'Tips sent to tip device'
    } catch (e) {
      closeModal()
      return 'Cannot send Tips to devices'
    }
  }
  if (null === currentRoom) {
    return <div></div>
  }

  return (
    <CommonModal
      title={`Send Tip to room ${currentRoom.name}`}
      cancelCallback={closeModal}
    >
      <GiveTipForm gameTip={gameTip} submitCallback={submitTip}>
        {null !== tips && <TipSelectField tips={tips} />}
      </GiveTipForm>
    </CommonModal>
  )
}
const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
  tips: state.gameTip.data,
  tipDevices: state.devices.data?.filter(
    device =>
      state.currentRoom?.data?.links?.includes(device.id) &&
      device.library === 'tip'
  ),
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    giveTip: ({ tip, device }: { tip: string; device: Device }) =>
      dispatch(giveTipRequest({ tip, device })),
    fetchRoomTips: ({ roomId }: { roomId: string }) =>
      dispatch(fetchRoomTips({ roomId })),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiveTipModalContainer)
