import { FaHome, FaBuilding, FaMobile } from 'react-icons/fa'
import { Route } from 'types/route'
import { Room } from 'types/room'

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const roomsToRoutes = (rooms: Room[]) => {
  return rooms.map(room => {
    return {
      path: `/app/rooms/${room.id}`,
      name: room.name,
    }
  })
}

const getRoutes = ({ rooms }: { rooms: Room[] }): Route[] => {
  return [
    {
      path: '/app/', // the url
      icon: FaHome, // the component being exported from icons/index.js
      name: 'Dashboard', // name that appear in Sidebar
    },
    {
      icon: FaBuilding,
      name: 'Rooms',
      path: '/app/rooms/',
      routes: roomsToRoutes(rooms),
    },
    {
      path: '/app/devices',
      icon: FaMobile,
      name: 'Devices',
    },
  ]
}

export { getRoutes }
