import React, { FunctionComponent } from 'react'
import { Router } from '@reach/router'
import DashboardContainer from 'modules/dashboard/dashboardContainer'
import DevicesContainer from 'modules/devices/devicesContainer'
import RoomEditor from 'modules/room/pages/roomEditor'
import SidebarLayout from 'layouts/fullWidthLayout'
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyForgotPassword,
  AmplifyConfirmSignIn,
} from '@aws-amplify/ui-react'
import { Hub } from '@aws-amplify/core'
import styles from 'pages/app.module.css'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { login, logout, setIsAdmin } from 'modules/user/state/actions'
import { connect } from 'react-redux'
import RegisterDeviceContainer from 'modules/devices/registerDeviceContainer'
import RoomsContainer from 'modules/rooms/roomsContainer'
import RoomHistory from 'modules/room/pages/roomHistory'
import RoomTips from 'modules/room/pages/roomTips'

type Props = {
  login: () => any
  logout: () => any
  setIsAdmin: ({ isAdmin }: { isAdmin: boolean }) => any
}

const AppIndex: FunctionComponent<Props> = ({ login, logout, setIsAdmin }) => {
  React.useEffect(() => {
    Hub.listen('auth', data => {
      const { payload } = data
      if (null !== payload.data) {
        const userGroups =
          payload.data.signInUserSession.accessToken.payload['cognito:groups']
        if (payload.event === 'signIn' && login) {
          login()
          if (userGroups.includes('admin') && setIsAdmin) {
            setIsAdmin({ isAdmin: true })
          }
        } else if (payload.event === 'signOut' && logout) {
          logout()
        }
      }
    })
  }, [])

  return (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignIn
        slot="sign-in"
        hideSignUp
        className={styles.cognitoContainer}
      />
      <AmplifyForgotPassword
        slot="forgot-password"
        className={styles.cognitoContainer}
      />
      <AmplifyConfirmSignIn
        slot="confirm-sign-in"
        className={styles.cognitoContainer}
      />

      <SidebarLayout>
        <Router basepath="/app">
          <DashboardContainer path={'/'} />
          <DevicesContainer path={'/devices'} />
          <RoomsContainer path={'/rooms'} />
          <RegisterDeviceContainer path={'/devices/register/:deviceId'} />
          <RoomEditor path={'/rooms/:roomId'} />
          <RoomHistory path={'/rooms/:roomId/history'} />
          <RoomTips path={'/rooms/:roomId/tips'} />
        </Router>
      </SidebarLayout>
    </AmplifyAuthenticator>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    login: () => dispatch(login()),
    logout: () => dispatch(logout()),
    setIsAdmin: ({ isAdmin }: { isAdmin: boolean }) =>
      dispatch(setIsAdmin({ isAdmin })),
  }
}
export default connect(null, mapDispatchToProps)(AppIndex)
