import React, { FunctionComponent, useEffect, useState } from 'react'
import { Operator, Rule } from 'types/operator'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { Device } from 'types/device'
import { TableRowCell } from 'modules/common/table/table'

type Props = {
  rule?: Rule
  devices?: Device[]
  operators?: Operator[]
}

const RuleView: FunctionComponent<Props> = ({ rule, devices, operators }) => {
  const [currentDevice, setCurrentDevice] = useState<Device | null>(null)
  const [currentOperator, setCurrentOperator] = useState<Operator | null>(null)

  useEffect(() => {
    if (devices && rule && 'device' === rule.type) {
      const deviceFound = devices.find(device => device.id === rule.id)
      if (typeof deviceFound !== 'undefined') {
        setCurrentDevice(deviceFound)
      }
    }
    if (operators && rule && 'operator' === rule.type) {
      const operatorFound = operators.find(operator => operator.id === rule.id)
      if (typeof operatorFound !== 'undefined') {
        setCurrentOperator(operatorFound)
      }
    }
  }, [devices, operators, rule])

  return (
    <tr className="bg-white">
      <TableRowCell className=" text-gray-500">{rule?.type}</TableRowCell>
      <TableRowCell className="font-medium text-gray-900">
        {currentDevice && currentDevice.name}
        {currentOperator && currentOperator.name}
      </TableRowCell>
      <TableRowCell className="text-gray-500">
        {rule?.value.toString()}
      </TableRowCell>
      <TableRowCell className=" text-gray-500">
        {rule?.state.toString()}
      </TableRowCell>
    </tr>
  )
}
const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
  operators: state.currentRoom.data?.operators,
})

export default connect(mapStateToProps)(RuleView)
