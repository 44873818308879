import React, { FunctionComponent } from 'react'
import MobileSidebar from './mobileSidebar'

const Sidebar: FunctionComponent = () => {
  return (
    <>
      <MobileSidebar />
    </>
  )
}

export default Sidebar
