import React from 'react'
import { ModalProvider } from './modalContainer'

export const withModal = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithModal extends React.Component<P> {
    render() {
      return (
        <ModalProvider>
          <WrappedComponent {...this.props} />
        </ModalProvider>
      )
    }
  }
