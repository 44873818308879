import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { ExtraDeviceView } from 'modules/room/components/extraDeviceView'
import { TableHeadCell } from 'modules/common/table/table'
import { CardHeader } from 'modules/common/cards/cardHeader'

const ExtraDevicesViewContainer: FunctionComponent<
  ReturnType<typeof mapStateToProps>
> = ({ extraDevices }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg my-4">
      <CardHeader>Extra Devices</CardHeader>
      <div className="px-4 py-5 sm:p-6">
        <table className="min-w-full w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <TableHeadCell>Device</TableHeadCell>
            </tr>
          </thead>
          <tbody>
            {extraDevices?.map((device, index) => (
              <ExtraDeviceView key={`extraDevices.${index}`} device={device} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  extraDevices: state.devices.data?.filter(device =>
    state.currentRoom?.data?.links?.includes(device.id)
  ),
})

export default connect(mapStateToProps)(ExtraDevicesViewContainer)
