import React, { FunctionComponent } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import { RoomInit } from 'types/room'
import Button from 'modules/common/buttons/button'
import TextField from 'modules/common/form/textField'

type SubmitCallback = (p: { room: RoomInit }) => Promise<{ room: RoomInit }>

type CreateForm = {
  submitCallback: SubmitCallback
}

const CreateForm: FunctionComponent<CreateForm> = ({ submitCallback }) => {
  return (
    <div>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(
          values: RoomInit,
          { setSubmitting }: FormikHelpers<RoomInit>
        ) => {
          submitCallback({ room: { ...values } }).then(() => {
            setSubmitting(false)
          })
        }}
      >
        <Form>
          <Field
            component={TextField}
            label="name"
            id="name"
            name="name"
            placeholder="Room Name"
          />

          <Button className="w-full sm:w-auto mt-6" type="submit">
            Save
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
export default CreateForm
