import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import EditForm from 'modules/room/components/editForm'
import { Room } from 'types/room'
import { editRequest, fetchRequest } from 'modules/room/state/actions'

type Props = {
  room: Room
} & ReturnType<typeof mapDispatchToProps>

const EditModalContainer: FunctionComponent<Props> = ({
  room,
  editCurrentRoom,
  fetchCurrentRoom,
}) => {
  const { handleModal } = useContext(ModalContext)

  const editCallback = async ({ room }: { room: Room }) => {
    await editCurrentRoom({ room }).then(() => {
      if (fetchCurrentRoom) {
        fetchCurrentRoom({ roomId: room.id })
      }
    })
    closeModal()
    return { room }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal title={`Edit Room`} cancelCallback={closeModal}>
        <EditForm room={room} submitCallback={editCallback} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchCurrentRoom: ({ roomId }: { roomId: string }) =>
      dispatch(fetchRequest({ roomId })),
    editCurrentRoom: ({ room }: { room: Room }) =>
      dispatch(editRequest({ room })),
  }
}

export default connect(null, mapDispatchToProps)(EditModalContainer)
