import React, { FunctionComponent } from 'react'

export const TableHeadCell: FunctionComponent = ({ children }) => {
  return (
    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
      {children}
    </th>
  )
}
type RowCellProps = {
  colSpan?: number
  className?: string
}
export const TableRowCell: FunctionComponent<RowCellProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <td
      className={`px-6 py-4 whitespace-no-wrap text-sm leading-5 ${
        typeof className !== 'undefined' ? className : ''
      }`}
      {...props}
    >
      {children}
    </td>
  )
}
