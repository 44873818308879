import { GameTipActionTypes } from './types'

import { Action, Dispatch, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { API, Auth } from 'aws-amplify'
import { addMessage } from 'modules/messages/state/actions'
import { AppThunk } from 'types/app'
import { GameTip } from 'types/gameTip'

export const fetchTip: AppThunk<null> = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: GameTipActionTypes.FETCH_REQUEST })
    try {
      const result = await API.get('TipsService', `/`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      return dispatch({
        type: GameTipActionTypes.FETCH_SUCCESS,
        payload: result.rooms,
      })
    } catch (e) {
      return dispatch({
        type: GameTipActionTypes.FETCH_ERROR,
      })
    }
  }
}

export const fetchRoomTips: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameTipActionTypes.FETCH_ROOM_REQUEST })
    try {
      const result = await API.get('TipsService', `/room/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      return dispatch({
        type: GameTipActionTypes.FETCH_ROOM_SUCCESS,
        payload: result.tips,
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: { body: 'Cannot load tips for room', type: 'danger' },
        })
      )
      return dispatch({
        type: GameTipActionTypes.FETCH_ROOM_ERROR,
      })
    }
  }
}

export const createTip: AppThunk<{ gameTip: GameTip }> = ({ gameTip }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState
  ): Promise<Action> => {
    const currentRoom = getState().currentRoom.data
    if (null === currentRoom) {
      return dispatch({
        type: GameTipActionTypes.CREATE_ERROR,
      })
    }
    dispatch({ type: GameTipActionTypes.CREATE_REQUEST })
    try {
      const result = await API.post('TipsService', `/`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: { ...gameTip, roomId: currentRoom.id },
      })
      await dispatch(fetchRoomTips({ roomId: currentRoom.id }))
      return dispatch({
        type: GameTipActionTypes.CREATE_SUCCESS,
        payload: result,
      })
    } catch (e) {
      return dispatch({
        type: GameTipActionTypes.CREATE_ERROR,
      })
    }
  }
}

export const editTip: AppThunk<{ gameTip: GameTip }> = ({ gameTip }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameTipActionTypes.EDIT_REQUEST })
    try {
      const result = await API.put('TipsService', `/${gameTip.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: gameTip,
      })
      await dispatch(fetchRoomTips({ roomId: gameTip.roomId }))
      return dispatch({
        type: GameTipActionTypes.EDIT_SUCCESS,
        payload: result,
      })
    } catch (e) {
      return dispatch({
        type: GameTipActionTypes.EDIT_ERROR,
      })
    }
  }
}

export const removeTip: AppThunk<{ gameTip: GameTip }> = ({ gameTip }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: GameTipActionTypes.REMOVE_REQUEST })
    try {
      const result = await API.del('TipsService', `/${gameTip.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      await dispatch(fetchRoomTips({ roomId: gameTip.roomId }))
      return dispatch({
        type: GameTipActionTypes.REMOVE_SUCCESS,
        payload: result,
      })
    } catch (e) {
      return dispatch({
        type: GameTipActionTypes.REMOVE_ERROR,
      })
    }
  }
}
