import React, { FunctionComponent } from 'react'
import { RuleChain } from 'types/operator'
import classNames from 'classnames'
import RulesView from 'modules/operator/components/rulesView'

type Props = {
  ruleChain?: RuleChain
  index: number
  keyPrefix: string
}

const RuleChainView: FunctionComponent<Props> = ({
  ruleChain,
  index,
  keyPrefix,
}) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg mb-6">
      <div className="flex justify-between">
        <div className="flex-1 bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                RuleChain {index}
              </h3>
            </div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {ruleChain?.logic && ruleChain?.logic.toUpperCase()}
              </h3>
            </div>
          </div>
        </div>

        <div>
          <span
            className={classNames({
              ['flex-shrink-0 flex items-center justify-center w-16 h-16 font-medium rounded-r-md text-white text-sm leading-5']: true,
              ['bg-green-400']: ruleChain?.state,
              ['bg-pink-600']: !ruleChain?.state,
            })}
          />
        </div>
      </div>
      <RulesView rules={ruleChain?.rules} keyPrefix={keyPrefix} />
    </div>
  )
}

export default RuleChainView
