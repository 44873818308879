import React, { FunctionComponent, useContext } from 'react'
import { DangerModal } from 'modules/common/modal/dangerModal'
import { Device } from 'types/device'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { removeRequest } from 'modules/devices/state/actions'
import { connect } from 'react-redux'

type Props = {
  device: Device
} & ReturnType<typeof mapDispatchToProps>

const RemoveModalContainer: FunctionComponent<Props> = ({
  device,
  removeDevice,
}) => {
  const { handleModal } = useContext(ModalContext)

  const removeCallback = async () => {
    await removeDevice({ device })
    closeModal()
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <DangerModal
        title={'Remove device'}
        content={`Are you sure you want to remove device ${device.name || ''}`}
        confirmButtonText={'Remove'}
        cancelButtonText={'Cancel'}
        cancelCallback={closeModal}
        successCallback={removeCallback}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    removeDevice: ({ device }: { device: Device }) =>
      dispatch(removeRequest({ device })),
  }
}

export default connect(null, mapDispatchToProps)(RemoveModalContainer)
