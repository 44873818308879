import React, { FunctionComponent } from 'react'

const RoomBottom: FunctionComponent = ({ children }) => {
  return (
    <div className="fixed left-0 bottom-0 w-full flex">
      <div className="z-30 flex-shrink-0 hidden w-64 lg:block" />
      <div className="flex flex-1 w-full justify-center">
        <div className="bg-white shadow-lg rounded-t-lg flex">{children}</div>
      </div>
    </div>
  )
}

export default RoomBottom
