import React, { FunctionComponent } from 'react'
import { Route } from 'types/route'
import Link from 'gatsby-link'

interface OwnProps {
  routes: Route[] | null
}

type Props = OwnProps

const TopNavigation: FunctionComponent<Props> = ({ routes }) => {
  return (
    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
      <div className="hidden md:block sm:ml-6">
        <div className="flex space-x-4">
          {null !== routes &&
            routes.map(route => (
              <Link
                key={route.path}
                to={typeof route.path !== 'undefined' ? route.path : '#'}
                className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                activeClassName="border-indigo-500 text-sm font-medium text-gray-900"
              >
                {route.name}
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TopNavigation
