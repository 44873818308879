import React, { FunctionComponent, useContext } from 'react'
import { Operator } from 'types/operator'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import EmptyCard from 'modules/common/cards/emptyCard'
import styles from 'modules/room/components/roomGrid.module.css'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { editOperatorRequest } from 'modules/room/state/actions'
import { Link } from 'gatsby'
import OperationsEditContainer from 'modules/operator/operationsEditContainer'
import { FastField, FieldArray } from 'formik'
import RuleChainEdit from 'modules/operator/components/ruleChainEdit'
import { ModalContext } from 'modules/common/modal/modalContainer'
import EditModalContainer from 'modules/operator/editModalContainer'
import TextField from 'modules/common/form/textField'

type Props = {
  currentOperator: Operator
  formNamePrefix: string
  removeCallback: () => any
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const OperatorEditContainer: FunctionComponent<Props> = ({
  currentOperator,
  formNamePrefix,
  currentRoom,
  removeCallback,
}) => {
  const { handleModal } = useContext(ModalContext)

  const openEditModal = () => {
    handleModal(
      <EditModalContainer room={currentRoom} operator={currentOperator} />
    )
  }

  const emptyRuleChain = {
    rules: [],
    logic: 'AND',
    state: false,
  }

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg my-4">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mr-2">
          Operator
        </h3>
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2 py-2 flex items-center flex-grow">
            <FastField
              component={TextField}
              label="Name"
              name={`${formNamePrefix}.name`}
              type="text"
            />
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <FastField
              component={TextField}
              label="Sort Order"
              name={`${formNamePrefix}.order`}
              type="number"
            />
          </div>
          <div className="ml-4 mt-8 flex-shrink-0">
            <span className="ml-4 inline-flex rounded-md shadow-sm">
              <button
                type="button"
                onClick={() => removeCallback()}
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className={`grid grid-cols-1 gap-4 gap-y-4 py-4 'xl:grid-cols-2'`}>
          <div>
            <FieldArray name={`${formNamePrefix}.ruleChains`}>
              {({ push }) => (
                <>
                  {currentOperator.ruleChains?.map((ruleChain, index) => {
                    return (
                      <RuleChainEdit
                        key={`${formNamePrefix}.ruleChains.${index}`}
                        ruleChain={ruleChain}
                        index={index}
                        formNamePrefix={`${formNamePrefix}.ruleChains.${index}`}
                      />
                    )
                  })}
                  <Link to="#" onClick={() => push(emptyRuleChain)}>
                    <EmptyCard>
                      <p className="text-lg font-semibold text-center text-gray-700 dark:text-gray-200">
                        <span className={styles.itemInner}>
                          Add new RuleChain
                        </span>
                      </p>
                    </EmptyCard>
                  </Link>
                </>
              )}
            </FieldArray>
          </div>
          <div>
            <OperationsEditContainer
              key={`${formNamePrefix}.operations`}
              operations={currentOperator.operations}
              formNamePrefix={formNamePrefix}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
  rooms: state.rooms.data,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    editOperator: ({
      operator,
      roomId,
    }: {
      operator: Operator
      roomId: string
    }) => dispatch(editOperatorRequest({ operator, roomId })),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorEditContainer)
