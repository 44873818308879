import React, { FunctionComponent } from 'react'

export const CardHeader: FunctionComponent = ({ children }) => {
  return (
    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-2">
            {children}
          </h3>
        </div>
      </div>
    </div>
  )
}
