import React, { FunctionComponent } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import Button from 'modules/common/buttons/button'
import { GameTip } from 'types/gameTip'

interface TipValues {
  tip: string
}
type SubmitCallback = (p: { tip: string }) => Promise<string>

type GiveTipForm = {
  submitCallback: SubmitCallback
  gameTip?: GameTip
}

const GiveTipForm: FunctionComponent<GiveTipForm> = ({
  submitCallback,
  gameTip,
  children,
}) => {
  return (
    <div>
      <Formik
        initialValues={{
          tip: typeof gameTip !== 'undefined' ? gameTip?.tip : '',
        }}
        onSubmit={(
          values: TipValues,
          { setSubmitting }: FormikHelpers<TipValues>
        ) => {
          submitCallback(values).then(() => {
            setSubmitting(false)
          })
        }}
      >
        <Form>
          <label
            htmlFor="tip"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          >
            Tip
          </label>
          <Field
            id="tip"
            name="tip"
            placeholder="Tip text"
            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          />
          {children}

          <Button className="w-full sm:w-auto mt-6" type="submit">
            Send
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
export default GiveTipForm
