import React, { FunctionComponent } from 'react'
import { Field } from 'formik'
import DeviceDropdownContainer from 'modules/devices/deviceDropdownContainer'
import { FaTrash } from 'react-icons/fa'
import { Relay } from 'types/relay'
import SelectField from 'modules/common/form/selectField'
import { TableRowCell } from 'modules/common/table/table'

type Props = {
  relay?: Relay
  index: number
  removeCallback?<T>(index: number): T | undefined
}

const RelayEdit: FunctionComponent<Props> = ({ index, removeCallback }) => {
  const namePrefix = `relays.${index}`

  return (
    <tr className="bg-white">
      <TableRowCell className="text-gray-500">
        <Field
          component={SelectField}
          name={`${namePrefix}.source`}
          placeholder="Select Source"
        >
          <DeviceDropdownContainer />
        </Field>
      </TableRowCell>
      <TableRowCell className="text-gray-500">
        <Field
          component={SelectField}
          name={`${namePrefix}.target`}
          placeholder="Select Target"
        >
          <DeviceDropdownContainer />
        </Field>
      </TableRowCell>
      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
        <div className="flex items-center space-x-4">
          {typeof removeCallback !== 'undefined' && (
            <span aria-label="Edit" onClick={() => removeCallback(index)}>
              <FaTrash className="w-5 h-5" aria-hidden="true" />
            </span>
          )}
        </div>
      </td>
    </tr>
  )
}

export default RelayEdit
