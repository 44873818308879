import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import OperatorViewContainer from 'modules/operator/operatorViewContainer'

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
})

const OperatorsViewContainer: FunctionComponent<
  ReturnType<typeof mapStateToProps>
> = ({ currentRoom }) => {
  return (
    <>
      {currentRoom?.operators?.map(operator => (
        <OperatorViewContainer
          key={operator.id}
          currentOperator={operator}
          keyPrefix={`operator.${operator.id}`}
        />
      ))}
    </>
  )
}

export default connect(mapStateToProps)(OperatorsViewContainer)
