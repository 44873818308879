import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import { GameTip } from 'types/gameTip'
import EditForm from 'modules/gameTip/components/editForm'
import { editTip } from 'modules/gameTip/state/actions'

type Props = {
  gameTip: GameTip
} & ReturnType<typeof mapDispatchToProps>

const EditModalContainer: FunctionComponent<Props> = ({
  gameTip,
  editGameTip,
}) => {
  const { handleModal } = useContext(ModalContext)

  const editCallback = async ({ gameTip }: { gameTip: GameTip }) => {
    await editGameTip({ gameTip })
    closeModal()
    return { gameTip }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal title={`Edit Tip`} cancelCallback={closeModal}>
        <EditForm gameTip={gameTip} submitCallback={editCallback} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    editGameTip: ({ gameTip }: { gameTip: GameTip }) =>
      dispatch(editTip({ gameTip })),
  }
}

export default connect(null, mapDispatchToProps)(EditModalContainer)
