import {
  THEME_TOGGLE,
  THEME_LIGHT,
  THEME_DARK,
  SIDEBAR_OPEN,
  SIDEBAR_CLOSED,
  SIDEBAR_TOGGLE,
} from 'modules/theme/types'
import { action } from 'typesafe-actions'

export const themeLight = () => action(THEME_LIGHT)

export const themeDark = () => action(THEME_DARK)

export const themeToggle = () => action(THEME_TOGGLE)

export const sidebarOpen = () => action(SIDEBAR_OPEN)

export const sidebarClosed = () => action(SIDEBAR_CLOSED)

export const sidebarToggle = () => action(SIDEBAR_TOGGLE)
