import { action } from 'typesafe-actions'
import { UserActionTypes } from 'modules/user/state/types'
import { Action, ActionCreator, AnyAction } from 'redux'
import { fetchRoomsList } from 'modules/room/state/actions'

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from 'state/rootReducer'

export type AppThunk = ActionCreator<
  ThunkAction<void, AppState, null, Action<string>>
>

export const login: AppThunk = () => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: UserActionTypes.LOGIN_REQUEST })
    dispatch(fetchRoomsList())
    return dispatch({
      type: UserActionTypes.LOGIN_SUCCESS,
    })
  }
}
export const logout = () => action(UserActionTypes.LOGOUT)

export const setIsAdmin = ({ isAdmin }: { isAdmin: boolean }) =>
  action(UserActionTypes.SET_IS_ADMIN, { isAdmin })
