import React, { FunctionComponent } from 'react'
import { GameClock } from 'types/gameClock'
import { differenceInMinutes, format, parseJSON } from 'date-fns'

type Props = { historyEntry: GameClock }

const GameHistoryEntry: FunctionComponent<Props> = ({ historyEntry }) => {
  const startTime = parseJSON(historyEntry.startTime)
  const endTime = parseJSON(historyEntry.endTime)
  const duration = differenceInMinutes(endTime, startTime)
  const expectedDuration = historyEntry.duration / 60000
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {historyEntry.participants.join(', ')}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {format(startTime, 'd.M.y H:mm:ss')}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {format(endTime, 'd.M.y k:mm:ss')}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {expectedDuration} min
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            duration < expectedDuration
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}
        >
          {duration} min
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {historyEntry.id}
      </td>
    </tr>
  )
}

export default GameHistoryEntry
