import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { Device } from 'types/device'

type Props = {
  devices?: Device[]
}

const DeviceDropdownContainer: FunctionComponent<Props> = ({ devices }) => {
  return (
    <>
      <option>Select device</option>
      {devices?.map(device => (
        <option key={device.id} value={device.id}>
          {device.name}
        </option>
      ))}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
})

export default connect(mapStateToProps)(DeviceDropdownContainer)
