import React from 'react'
import { RoomProvider } from 'modules/room/roomContextProvider'

export const withRoom = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithRoom extends React.Component<P> {
    render() {
      return (
        <RoomProvider>
          <WrappedComponent {...this.props} />
        </RoomProvider>
      )
    }
  }
