import React, { FunctionComponent } from 'react'
import { CardHeader } from 'modules/common/cards/cardHeader'

type Props = {
  participants: string[]
}

const GameParticipants: FunctionComponent<Props> = ({ participants }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg my-4">
      <CardHeader>Current Game Participants</CardHeader>
      <div className="px-4 py-5 sm:p-6">
        <ul>
          {participants.map(participant => (
            <li key={participant}>{participant}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default GameParticipants
