import React, { FunctionComponent } from 'react'
import { Rule, SequenceRule } from 'types/operator'
import { FieldArray } from 'formik'
import Button from 'modules/common/buttons/button'
import RuleEdit from 'modules/operator/ruleEdit'
import RuleSequenceEdit from 'modules/operator/components/ruleSequenceEdit'
import { TableHeadCell, TableRowCell } from 'modules/common/table/table'

type Props = {
  rules?: Rule[]
  ruleChainIndex: number
  formNamePrefix: string
}
const emptyRule: Rule = {
  id: '',
  type: 'device',
  value: '',
  state: false,
}

const emptySequenceRule: SequenceRule = {
  id: '',
  type: 'sequence',
  value: [],
  state: false,
}

const RulesEdit: FunctionComponent<Props> = ({ rules, formNamePrefix }) => {
  return (
    <div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <TableHeadCell>Type</TableHeadCell>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Expected Value</TableHeadCell>
                    <TableHeadCell>Actions</TableHeadCell>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name={`${formNamePrefix}.rules`}>
                    {({ remove, push }) => (
                      <>
                        {rules?.map((rule, index) => {
                          if (
                            typeof rule.type !== 'undefined' &&
                            'sequence' === rule.type
                          ) {
                            const sequenceRule = (rule as any) as SequenceRule
                            return (
                              <RuleSequenceEdit
                                key={`${formNamePrefix}.rule${index}`}
                                rule={sequenceRule}
                                index={index}
                                removeCallback={remove}
                                formNamePrefix={`${formNamePrefix}.rules.${index}`}
                              />
                            )
                          } else {
                            return (
                              <RuleEdit
                                key={`${formNamePrefix}.rule${index}`}
                                rule={rule}
                                index={index}
                                removeCallback={remove}
                                formNamePrefix={`${formNamePrefix}.rules.${index}`}
                              />
                            )
                          }
                        })}
                        <tr className="bg-white">
                          <TableRowCell
                            className="font-medium text-gray-900 border-dashed border-t text-center"
                            colSpan={5}
                          >
                            <Button
                              type="button"
                              onClick={() => push(emptyRule)}
                            >
                              Add new rule
                            </Button>
                            <Button
                              type="button"
                              onClick={() => push(emptySequenceRule)}
                              className="ml-4"
                            >
                              Add new sequence rule
                            </Button>
                          </TableRowCell>
                        </tr>
                      </>
                    )}
                  </FieldArray>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RulesEdit
