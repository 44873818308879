import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import Button from 'modules/common/buttons/button'
import GiveTipModalContainer from 'modules/gameTip/giveTipModalContainer'

const GiveTipContainer: FunctionComponent = () => {
  const { handleModal } = useContext(ModalContext)

  const openModal = () => {
    handleModal(<GiveTipModalContainer />)
  }

  return <Button onClick={() => openModal()}>Give Tip</Button>
}
export default GiveTipContainer
