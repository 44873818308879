import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  onClick?: () => any
  type?: 'submit' | 'button'
}

const Button: FunctionComponent<Props> = ({
  type,
  onClick,
  className,
  children,
}) => {
  return (
    <span
      className={classNames([className, 'inline-flex rounded-md shadow-sm'])}
    >
      <button
        type={typeof type !== 'undefined' ? type : 'button'}
        onClick={onClick}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
      >
        {children}
      </button>
    </span>
  )
}

export default Button
