import React, { FunctionComponent } from 'react'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import { Operator } from 'types/operator'
import OperatorEditContainer from 'modules/operator/operatorEditContainer'
import Link from 'gatsby-link'
import EmptyCard from 'modules/common/cards/emptyCard'
import styles from 'modules/room/components/roomGrid.module.css'
import Button from 'modules/common/buttons/button'
import { connect } from 'react-redux'
import { AppState } from 'state/rootReducer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AppMessage } from 'modules/messages/state/types'
import { addMessage } from 'modules/messages/state/actions'
import { batchEditOperatorRequest } from 'modules/room/state/actions'

const OperatorsEditContainer: FunctionComponent<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ currentRoom, batchEditOperator }) => {
  const emptyOperator: Operator = {
    name: 'Name',
    order: 99,
    operations: [
      {
        id: '',
        value: '',
        state: false,
        runOnce: false,
        type: 'device',
      },
    ],
    ruleChains: [
      {
        rules: [],
        logic: 'AND',
        state: false,
      },
    ],
  }
  const handleOperators = ({
    operators,
    setSubmitting,
  }: {
    operators: Operator[]
    setSubmitting: (isSubmitting: boolean) => void
  }): Promise<any> => {
    if (batchEditOperator && currentRoom) {
      return new Promise((resolve, reject) => {
        batchEditOperator({ operators, roomId: currentRoom.id })
          .then(value => resolve(value))
          .catch(() => reject('Cannot update Operators'))
          .finally(() => {
            setSubmitting(false)
          })
      })
    } else {
      return new Promise(reject => {
        reject('Cannot update Operators')
      })
    }
  }

  return (
    <Formik
      initialValues={{
        operators: currentRoom?.operators || [emptyOperator],
      }}
      onSubmit={(
        values: { operators: Operator[] },
        {
          setSubmitting,
        }: FormikHelpers<{
          operators: Operator[]
        }>
      ) =>
        handleOperators({
          operators: values.operators,
          setSubmitting,
        })
      }
    >
      {({ values }) => (
        <Form>
          <FieldArray name={`operators`}>
            {({ push, remove }) => (
              <>
                {values?.operators?.map((operator, index) => (
                  <OperatorEditContainer
                    key={`operator.${index}`}
                    currentOperator={values.operators[index]}
                    formNamePrefix={`operators.${index}`}
                    removeCallback={() => remove(index)}
                  />
                ))}
                <Link to="#" onClick={() => push(emptyOperator)}>
                  <EmptyCard>
                    <p className="text-lg font-semibold text-center text-gray-700 dark:text-gray-200">
                      <span className={styles.itemInner}>Add new Operator</span>
                    </p>
                  </EmptyCard>
                </Link>
              </>
            )}
          </FieldArray>
          <Button type="submit">Save </Button>
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentRoom: state.currentRoom.data,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    addMessage: ({ message }: { message: AppMessage }) =>
      dispatch(addMessage({ message })),
    batchEditOperator: ({
      operators,
      roomId,
    }: {
      operators: Array<Operator>
      roomId: string
    }) => dispatch(batchEditOperatorRequest({ operators, roomId })),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorsEditContainer)
