import React, { FunctionComponent } from 'react'
import { FieldProps } from 'formik'

const SelectField: FunctionComponent<FieldProps & { label: string }> = ({
  label,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  children,
  ...props
}) => {
  return (
    <>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label && <span className="text-gray-700">{label}</span>}
        <select
          id={field.name}
          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          {...field}
          {...props}
        >
          {children}
        </select>
      </label>
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </>
  )
}
export default SelectField
