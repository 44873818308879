import { RoomActionTypes, RoomsActionTypes } from 'modules/room/state/types'

import { Action, Dispatch, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { API, Auth } from 'aws-amplify'
import { GameClockActionTypes } from 'modules/gameClock/state/types'
import { addMessage } from 'modules/messages/state/actions'
import { Room, RoomInit } from 'types/room'
import { Operator, OperatorInit } from 'types/operator'
import { Relay } from 'types/relay'
import { AppThunk } from 'types/app'
import { fetchRequest as fetchDevices } from 'modules/devices/state/actions'
import { Device } from 'types/device'
import { action } from 'typesafe-actions'

export const fetchRoomsList: AppThunk<null> = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({ type: RoomsActionTypes.FETCH_REQUEST })
    try {
      const result = await API.get('RoomsService', `/`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      return dispatch({
        type: RoomsActionTypes.FETCH_SUCCESS,
        payload: result.rooms,
      })
    } catch (e) {
      return dispatch({
        type: RoomsActionTypes.FETCH_ERROR,
      })
    }
  }
}

export const fetchRequest: AppThunk<{ roomId: string }> = ({ roomId }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.FETCH_REQUEST, payload: { id: roomId } })
    try {
      const result = await API.get('RoomsService', `/${roomId}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      dispatch({
        type: GameClockActionTypes.FETCH_SUCCESS,
        payload:
          typeof result.gameClock !== 'undefined' ? result.gameClock : null,
      })
      return dispatch({
        type: RoomActionTypes.FETCH_SUCCESS,
        payload: result,
      })
    } catch (e) {
      console.log(e)
      dispatch(
        addMessage({
          message: { body: 'Cannot load room', type: 'danger' },
        })
      )
      return dispatch({
        type: RoomActionTypes.FETCH_ERROR,
      })
    }
  }
}

export const createRequest: AppThunk<{ room: RoomInit }> = ({ room }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.CREATE_REQUEST })
    try {
      const result = await API.post('RoomsService', `/`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: room,
      })
      await dispatch(fetchRoomsList())
      dispatch(
        addMessage({
          message: { body: 'Room created', type: 'success' },
        })
      )
      return dispatch({
        type: RoomActionTypes.CREATE_SUCCESS,
        payload: result,
      })
    } catch (e) {
      return dispatch({
        type: RoomActionTypes.CREATE_ERROR,
      })
    }
  }
}

export const editRequest: AppThunk<{ room: Room }> = ({ room }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.EDIT_REQUEST })
    try {
      const result = await API.put('RoomsService', `/${room.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: room,
      })
      dispatch(fetchRoomsList())
      dispatch(
        addMessage({
          message: { body: 'Room edited', type: 'success' },
        })
      )
      return dispatch({
        type: RoomActionTypes.EDIT_SUCCESS,
        payload: result,
      })
    } catch (e) {
      return dispatch({
        type: RoomActionTypes.EDIT_ERROR,
      })
    }
  }
}

export const createOperatorRequest: AppThunk<{
  operator: OperatorInit
  roomId: string
  skipUpdate: boolean
}> = ({ operator, roomId, skipUpdate = false }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.CREATE_OPERATOR_REQUEST })
    try {
      const result = await API.post('OperatorsService', `/`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: { ...operator, roomId },
      })
      if (!skipUpdate) {
        await dispatch(fetchRequest({ roomId }))
      }
      return dispatch({
        type: RoomActionTypes.CREATE_OPERATOR_SUCCESS,
        payload: { operator: result },
      })
    } catch (e) {
      return dispatch({
        type: RoomActionTypes.CREATE_OPERATOR_ERROR,
      })
    }
  }
}

export const editOperatorRequest: AppThunk<{
  operator: OperatorInit
  roomId: string
  skipUpdate: boolean
}> = ({ operator, roomId, skipUpdate = false }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    if (typeof operator.id === 'undefined') {
      //New operator doesnt have ID, no need to PUT new data
      return dispatch({
        type: RoomActionTypes.EDIT_OPERATOR_SUCCESS,
        payload: { operator },
      })
    }

    dispatch({ type: RoomActionTypes.EDIT_OPERATOR_REQUEST })
    try {
      const result = await API.put('OperatorsService', `/${operator.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: operator,
      })
      if (!skipUpdate) {
        await dispatch(fetchRequest({ roomId }))
        dispatch(
          addMessage({
            message: { body: 'Operator edited', type: 'success' },
          })
        )
      }
      return dispatch({
        type: RoomActionTypes.EDIT_OPERATOR_SUCCESS,
        payload: { operator: result },
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: { body: 'Cannot edit Operator', type: 'danger' },
        })
      )
      return dispatch({
        type: RoomActionTypes.EDIT_OPERATOR_ERROR,
      })
    }
  }
}

export const removeOperatorRequest: AppThunk<{
  operator: Operator
  skipUpdate: boolean
}> = ({ operator, roomId, skipUpdate = false }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.REMOVE_OPERATOR_REQUEST })
    try {
      const result = await API.del('OperatorsService', `/${operator.id}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
      })
      if (!skipUpdate) {
        await dispatch(fetchRequest({ roomId }))
        dispatch(
          addMessage({
            message: { body: 'Operator removed', type: 'success' },
          })
        )
      }
      return dispatch({
        type: RoomActionTypes.REMOVE_OPERATOR_SUCCESS,
        payload: { operator: result },
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: { body: 'Cannot remove Operator', type: 'danger' },
        })
      )
      return dispatch({
        type: RoomActionTypes.REMOVE_OPERATOR_ERROR,
      })
    }
  }
}

export const batchEditOperatorRequest: AppThunk<{
  operators: Array<Operator | OperatorInit>
  roomId: string
}> = ({
  operators,
  roomId,
}: {
  operators: Array<Operator | OperatorInit>
  roomId: string
}) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.BATCH_EDIT_OPERATOR_REQUEST })
    try {
      const result = await API.post('RoomsService', `/${roomId}/operators`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: { operators },
      })
      await dispatch(fetchRequest({ roomId }))
      dispatch(
        addMessage({
          message: { body: 'Operators edited', type: 'success' },
        })
      )
      return dispatch({
        type: RoomActionTypes.BATCH_EDIT_OPERATOR_SUCCESS,
        payload: { operators: result },
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: { body: 'Cannot edit Operators', type: 'danger' },
        })
      )
      return dispatch({
        type: RoomActionTypes.BATCH_EDIT_OPERATOR_ERROR,
      })
    }
  }
}

export const setRelaysRequest: AppThunk<{
  relays: Relay[]
  roomId: string
}> = ({ relays, roomId }: { relays: Relay[]; roomId: string }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.SET_RELAYS_REQUEST })

    try {
      await API.post('RoomsService', `/${roomId}/relays`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          relays: relays.flatMap(relay => {
            return [relay.source, relay.target]
          }),
        },
      })
      await dispatch(fetchRequest({ roomId }))
      dispatch(
        addMessage({
          message: { body: 'Relays set to room', type: 'success' },
        })
      )
      return dispatch({
        type: RoomActionTypes.SET_RELAYS_SUCCESS,
        payload: { relays },
      })
    } catch (e) {
      dispatch(
        addMessage({
          message: { body: 'Cannot set relays to room', type: 'danger' },
        })
      )
      return dispatch({
        type: RoomActionTypes.SET_RELAYS_ERROR,
      })
    }
  }
}

export const setIsDesignerMode: AppThunk<boolean> = (payload: boolean) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    await dispatch(fetchDevices())
    return dispatch({
      type: RoomActionTypes.SET_IS_DESIGNER_MODE,
      payload,
    })
  }
}

export const addExtraDevicesRequest: AppThunk<{
  extraDevices: Device[]
  roomId: string
}> = ({ extraDevices, roomId }: { extraDevices: Device[]; roomId: string }) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    dispatch({ type: RoomActionTypes.ADD_EXTRA_DEVICES_REQUEST })
    try {
      await API.post('RoomsService', `/${roomId}/link`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentAuthenticatedUser())
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken()}`,
        },
        body: { devices: extraDevices.map(extraDevice => extraDevice.id) },
      })
      await dispatch(fetchRequest({ roomId }))
      return dispatch({
        type: RoomActionTypes.ADD_EXTRA_DEVICES_SUCCESS,
      })
    } catch (e) {
      return dispatch({
        type: RoomActionTypes.ADD_EXTRA_DEVICES_ERROR,
      })
    }
  }
}

export const setIsPolling = (payload: boolean) =>
  action(RoomActionTypes.SET_IS_POLLING, payload)
