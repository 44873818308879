import { AppMessage, MessagesActionTypes } from 'modules/messages/state/types'

import { action } from 'typesafe-actions'
import { AppThunk } from 'types/app'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'

export const addMessage: AppThunk<{ message: AppMessage }> = ({
  message,
}: {
  message: AppMessage
}) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>
  ): Promise<Action> => {
    if (message.type === 'success') {
      setTimeout(() => dispatch(clearMessages()), 5000)
    }
    return dispatch({
      type: MessagesActionTypes.ADD_MESSAGE,
      payload: {
        message,
      },
    })
  }
}

export const removeMessage = ({ messageId }: { messageId: string }) =>
  action(MessagesActionTypes.REMOVE_MESSAGE, { messageId })

export const clearMessages = () => action(MessagesActionTypes.CLEAR_MESSAGES)
