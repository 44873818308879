import React, { FunctionComponent } from 'react'
import { FastField } from 'formik'
import BooleanField from 'modules/common/form/booleanField'
import SelectField from 'modules/common/form/selectField'
import TextField from 'modules/common/form/textField'

type FieldTypeByLibraryProps = {
  formNamePrefix: string
  ruleLibrary: string
}

export const FieldTypeByLibrary: FunctionComponent<FieldTypeByLibraryProps> = ({
  formNamePrefix,
  ruleLibrary,
}) => {
  const renderFieldType = ({
    formNamePrefix,
    ruleLibrary,
  }: FieldTypeByLibraryProps) => {
    switch (ruleLibrary) {
      case 'boolean':
        return (
          <FastField
            component={BooleanField}
            name={`${formNamePrefix}.value`}
          />
        )
      case 'gameClock':
        return (
          <FastField component={SelectField} name={`${formNamePrefix}.value`}>
            <option value="resume">Resume</option>
            <option value="stop">Stop</option>
          </FastField>
        )
      default:
        return (
          <FastField
            component={TextField}
            name={`${formNamePrefix}.value`}
            type="text"
          />
        )
    }
  }
  return renderFieldType({ formNamePrefix, ruleLibrary })
}
