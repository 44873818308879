import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
  ReactNode,
} from 'react'

import PageTitle from 'modules/common/typography/pageTitle'
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Pagination,
} from '@windmill/react-ui'
import Button from 'modules/common/buttons/button'

import { FaEdit, FaTrash } from 'react-icons/fa'

import { RouteComponentProps } from '@reach/router'
import { Device } from 'types/device'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { fetchRequest } from 'modules/devices/state/actions'
import { ModalContext } from 'modules/common/modal/modalContainer'
import RemoveModalContainer from 'modules/devices/removeModalContainer'
import EditModalContainer from 'modules/devices/editModalContainer'
import RegisterModalContainer from 'modules/devices/registerModalContainer'
import { TableHeadCell } from 'modules/common/table/table'

type ResponseType = Array<Device>

type Props = {
  devices: Device[]
  fetchDevices: () => any
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DevicesContainer: FunctionComponent<RouteComponentProps<Props>> = ({
  devices,
  fetchDevices,
}) => {
  const [data, setData] = useState<Device[] | null | undefined>(null)
  const [pageTable, setPageTable] = useState(1)
  const [dataTable, setDataTable] = useState<ResponseType | null | undefined>(
    []
  )
  const [totalResults, setTotalResults] = useState(0)
  const [deviceFilter, setDeviceFilter] = useState<string>()

  const { handleModal } = useContext(ModalContext)

  useEffect(() => {
    if (fetchDevices) {
      fetchDevices()
    }
  }, [])

  useEffect(() => {
    if (typeof devices !== 'undefined') {
      setData(devices)
      setTotalResults(devices.length)
    } else {
      setData([])
      setTotalResults(0)
    }
  }, [devices])

  useEffect(() => {
    setDataTable(data?.slice(0, pageTable * resultsPerPage))
  }, [data])

  useEffect(() => {
    if (null !== data) {
      const filtered = data?.filter(filterDevice)
      setDataTable(
        filtered?.slice(
          (pageTable - 1) * resultsPerPage,
          pageTable * resultsPerPage
        )
      )
      setTotalResults(filtered?.length || 0)
    }
  }, [pageTable, deviceFilter])

  const filterDevice = (device: Device) => {
    return (
      !deviceFilter ||
      device.name.toLowerCase().includes(deviceFilter.toLowerCase()) ||
      device.id.toLowerCase().includes(deviceFilter.toLowerCase()) ||
      device.library.toLowerCase().includes(deviceFilter.toLowerCase()) ||
      device.state
        ?.toString()
        .toLowerCase()
        .includes(deviceFilter.toLowerCase())
    )
  }

  const openEditModal = ({ device }: { device: Device }) => {
    handleModal(<EditModalContainer device={device} />)
  }

  const openRegisterModal = () => {
    handleModal(<RegisterModalContainer />)
  }

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  const onPageChangeTable = (p: number) => {
    setPageTable(p)
  }

  const typeFromState = (
    state: string | boolean | number | null | undefined
  ) => {
    if (typeof state === 'undefined' || !state) {
      return 'danger'
    }
    if ('true' === state.toString()) {
      return 'success'
    }
    if ('false' === state.toString()) {
      return 'danger'
    }
    if (state) {
      return 'success'
    }
    return 'danger'
  }

  const deviceValueToHuman = (device: Device): ReactNode => {
    if ('sequence' === device.library && Array.isArray(device.state)) {
      const state = (device.state as any) as any[]
      return state.map(value => {
        return (
          <Badge key={value} type={typeFromState(device.state)}>
            {value}
          </Badge>
        )
      })
    }
    return (
      <Badge type={typeFromState(device.state)}>
        {typeof device.state !== 'undefined' && null !== device.state
          ? device.state.toString()
          : 'NULL'}
      </Badge>
    )
  }

  const removeDevice = ({ device }: { device: Device }) => {
    handleModal(<RemoveModalContainer device={device} />)
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <PageTitle>Devices</PageTitle>
        <div className="w-1/3">
          <input
            type="text"
            placeholder="Search devices"
            onChange={event => setDeviceFilter(event.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <Button onClick={() => openRegisterModal()}>Pair new Device</Button>
      </div>

      <TableContainer className="mb-8">
        <Table>
          <TableHeader>
            <tr>
              <TableHeadCell>Device</TableHeadCell>
              <TableHeadCell>Library</TableHeadCell>
              <TableHeadCell>State</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </tr>
          </TableHeader>
          <TableBody>
            {typeof dataTable !== 'undefined' &&
              null !== dataTable &&
              dataTable.map((device, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div className="flex items-center text-sm">
                      <div>
                        <p className="font-semibold">{device.name}</p>
                        <p className="text-xs text-gray-600 dark:text-gray-400">
                          {device.id}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <span className="text-sm">{device.library}</span>
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-col items-start space-y-2">
                      {deviceValueToHuman(device)}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center space-x-4">
                      <span
                        aria-label="Edit"
                        onClick={() => openEditModal({ device })}
                      >
                        <FaEdit className="w-5 h-5" aria-hidden="true" />
                      </span>
                      <span
                        aria-label="Delete"
                        onClick={() => removeDevice({ device })}
                      >
                        <FaTrash className="w-5 h-5" aria-hidden="true" />
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={totalResults}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}
const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchDevices: () => dispatch(fetchRequest()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DevicesContainer)
