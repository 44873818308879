import React, { FunctionComponent } from 'react'
import { AppMessage } from 'modules/messages/state/types'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { removeMessage } from 'modules/messages/state/actions'
import { connect } from 'react-redux'
import Message from 'modules/messages/components/message'

const MessageContainer: FunctionComponent<{
  message: AppMessage
  removeMessage: ({ messageId }: { messageId: string }) => any
}> = ({ message, removeMessage }) => {
  const remove = ({ message }: { message: AppMessage }) => {
    if (removeMessage && typeof message.id !== 'undefined') {
      removeMessage({ messageId: message.id })
    }
  }

  return <Message message={message} remove={remove} />
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    removeMessage: ({ messageId }: { messageId: string }) =>
      dispatch(removeMessage({ messageId })),
  }
}

export default connect(null, mapDispatchToProps)(MessageContainer)
