import React, { FunctionComponent } from 'react'
import { Rule, SequenceRule } from 'types/operator'
import { FieldArray } from 'formik'
import RuleView from 'modules/operator/ruleView'
import RuleSequenceView from 'modules/operator/ruleSequenceView'
import { TableHeadCell } from 'modules/common/table/table'

type Props = {
  rules?: Rule[]
  keyPrefix: string
}

const RulesView: FunctionComponent<Props> = ({ rules, keyPrefix }) => {
  return (
    <div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <TableHeadCell>Type</TableHeadCell>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Expected Value</TableHeadCell>
                    <TableHeadCell>State</TableHeadCell>
                  </tr>
                </thead>
                <tbody>
                  {rules?.map((rule, index) => {
                    if (
                      typeof rule.type !== 'undefined' &&
                      'sequence' === rule.type
                    ) {
                      const sequenceRule = (rule as any) as SequenceRule
                      return (
                        <RuleSequenceView
                          key={`${keyPrefix}.rule${index}`}
                          rule={sequenceRule}
                        />
                      )
                    } else {
                      return (
                        <RuleView
                          key={`${keyPrefix}.rule${index}`}
                          rule={rule}
                        />
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RulesView
