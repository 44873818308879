import React, { FunctionComponent, useContext } from 'react'
import { ModalContext } from 'modules/common/modal/modalContainer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { CommonModal } from 'modules/common/modal/commonModal'
import { Room } from 'types/room'
import { editOperatorRequest } from 'modules/room/state/actions'
import { Operator } from 'types/operator'
import EditForm from 'modules/operator/components/editForm'

type Props = {
  room: Room | null
  operator: Operator
} & ReturnType<typeof mapDispatchToProps>

const EditModalContainer: FunctionComponent<Props> = ({
  operator,
  room,
  editOperator,
}) => {
  const { handleModal } = useContext(ModalContext)

  const editCallback = async ({ operator }: { operator: Operator }) => {
    if (null !== room) {
      await editOperator({ operator, roomId: room.id })
    }

    closeModal()
    return { operator }
  }

  const closeModal = () => {
    handleModal('')
  }

  return (
    <>
      <CommonModal
        title={`Edit Operator ${operator.name}`}
        cancelCallback={closeModal}
      >
        <EditForm operator={operator} submitCallback={editCallback} />
      </CommonModal>
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    editOperator: ({
      operator,
      roomId,
    }: {
      operator: Operator
      roomId: string
    }) => dispatch(editOperatorRequest({ operator, roomId })),
  }
}

export default connect(null, mapDispatchToProps)(EditModalContainer)
