import React, { FunctionComponent } from 'react'

type Props = {
  title: string
}

const PageTitleWithActions: FunctionComponent<Props> = ({
  title,
  children,
}) => {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
          {title}
        </h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">{children}</div>
    </div>
  )
}

export default PageTitleWithActions
