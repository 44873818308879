import React, { FunctionComponent, useEffect } from 'react'
import { AppState } from 'state/rootReducer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { fetchListRequest } from 'modules/gameHistory/state/actions'
import GameHistoryEntry from 'modules/gameHistory/components/gameHistoryEntry'

type Props = { roomId?: string } & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const GameHistoryContainer: FunctionComponent<Props> = ({
  roomId,
  gameHistory,
  fetchRoomGames,
}) => {
  useEffect(() => {
    if (typeof roomId !== 'undefined') {
      if (fetchRoomGames) {
        fetchRoomGames({ roomId })
      }
    }
  }, [roomId])

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Participiants
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Start Time
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              End Time
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Expected Duration
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Duration
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              ID
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {gameHistory?.map(historyEntry => (
            <GameHistoryEntry
              key={historyEntry.id}
              historyEntry={historyEntry}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  gameHistory: state.gameHistory.data,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRoomGames: ({ roomId }: { roomId: string }) =>
      dispatch(fetchListRequest({ roomId })),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameHistoryContainer)
