import React, { FunctionComponent } from 'react'
import { Operation, SequenceOperation } from 'types/operator'
import { FieldArray } from 'formik'
import Button from 'modules/common/buttons/button'
import OperationEdit from 'modules/operator/components/operationEdit'
import { TableHeadCell, TableRowCell } from 'modules/common/table/table'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { DeviceLibrary } from 'types/device'
import OperationSequenceEdit from 'modules/operator/components/operationSequenceEdit'

type Props = {
  operations?: Operation[]
  formNamePrefix: string
} & ReturnType<typeof mapStateToProps>
const emptyOperation: Operation = {
  id: '',
  value: '',
  state: false,
  type: 'device',
}

const emptySequenceOperation: SequenceOperation = {
  id: '',
  value: [
    {
      value: '',
      interval: 100,
    },
  ],
  state: false,
  type: 'sequence',
}

const OperationsEditContainer: FunctionComponent<Props> = ({
  operations,
  formNamePrefix,
  devices,
}) => {
  const getOperationLibrary = (operation: Operation): DeviceLibrary => {
    const deviceFound = devices.find(device => device.id === operation.id)
    return deviceFound?.library || 'string'
  }
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="bg-white px-4 py-7 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Operations
            </h3>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <TableHeadCell>ID</TableHeadCell>
                      <TableHeadCell>Set Value</TableHeadCell>
                      <TableHeadCell>Run Once</TableHeadCell>
                      <TableHeadCell>Actions</TableHeadCell>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={`${formNamePrefix}.operations`}>
                      {({ remove, push }) => (
                        <>
                          {operations?.map((operation, index) =>
                            operation.type === 'sequence' ? (
                              <OperationSequenceEdit
                                key={`${formNamePrefix}.operation${index}`}
                                operation={operation}
                                index={index}
                                removeCallback={remove}
                                formNamePrefix={`${formNamePrefix}.operations.${index}`}
                              />
                            ) : (
                              <OperationEdit
                                key={`${formNamePrefix}.operation${index}`}
                                index={index}
                                removeCallback={remove}
                                formNamePrefix={`${formNamePrefix}.operations.${index}`}
                                library={getOperationLibrary(operation)}
                              />
                            )
                          )}
                          <tr className="bg-white">
                            <TableRowCell
                              className="font-medium text-gray-900 border-dashed border-t text-center"
                              colSpan={5}
                            >
                              <Button
                                type="button"
                                onClick={() => push(emptyOperation)}
                              >
                                Add new operation
                              </Button>
                              <Button
                                type="button"
                                onClick={() => push(emptySequenceOperation)}
                                className="ml-4"
                              >
                                Add new sequence operation
                              </Button>
                            </TableRowCell>
                          </tr>
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
})
export default connect(mapStateToProps)(OperationsEditContainer)
