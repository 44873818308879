import React, { FunctionComponent } from 'react'
import { useRoomContext } from './useRoomContext'
import { RoomContext } from './roomContext'

const { Provider } = RoomContext

const RoomProvider: FunctionComponent = ({ children }) => {
  const {
    isStartGameModalOpen,
    isEditModalOpen,
    setIsStartGameModalOpen,
    setIsEditModalOpen,
  } = useRoomContext()

  return (
    <Provider
      value={{
        isStartGameModalOpen,
        setIsStartGameModalOpen,
        isEditModalOpen,
        setIsEditModalOpen,
      }}
    >
      {children}
    </Provider>
  )
}

export { RoomContext, RoomProvider }
