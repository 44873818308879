import React, { ElementType, FunctionComponent, ReactElement } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

type Props = {
  title: string
  value: ReactElement | string
  icon?: ElementType
  type: 'success' | 'danger'
  link?: string
}

const CardWithIcon: FunctionComponent<Props> = ({
  title,
  value,
  type,
  link,
  icon,
  children,
}) => {
  const Icon: FunctionComponent = () => {
    if (typeof icon === 'undefined') {
      return null
    }
    const IconElement: ElementType = icon
    return <IconElement className="h-4 w-4" />
  }

  return (
    <div className="col-span-1 flex shadow-sm rounded-md">
      <div
        className={classNames({
          ['flex-shrink-0 flex items-center justify-center w-16 font-medium rounded-l-md text-white text-sm leading-5']: true,
          ['bg-green-400']: type !== 'danger',
          ['bg-pink-600']: type === 'danger',
        })}
      >
        <Icon />
      </div>

      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
        <div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
          {typeof link !== 'undefined' ? (
            <Link
              to={link}
              className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150"
            >
              {title}
            </Link>
          ) : (
            <h4 className="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
              {title}
            </h4>
          )}

          <p className="text-gray-500">{value}</p>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CardWithIcon
