import React, { FunctionComponent } from 'react'
import { SequenceRule } from 'types/operator'
import { FastField, FieldArray } from 'formik'
import DeviceDropdown from 'modules/devices/deviceDropdownContainer'
import { FaTrash } from 'react-icons/fa'
import Button from 'modules/common/buttons/button'
import SelectField from 'modules/common/form/selectField'
import { TableRowCell } from 'modules/common/table/table'

type Props = {
  rule?: SequenceRule
  index: number
  removeCallback?<T>(index: number): T | undefined
  formNamePrefix: string
}

const RuleSequenceEdit: FunctionComponent<Props> = ({
  rule,
  index,
  removeCallback,
  formNamePrefix,
}) => {
  return (
    <>
      <tr className="bg-white">
        <TableRowCell className="text-gray-500">
          <FastField name={`${formNamePrefix}.type`} as="span">
            <option value="device">Device</option>
          </FastField>
        </TableRowCell>
        <TableRowCell className="text-gray-500">
          <FastField
            component={SelectField}
            name={`${formNamePrefix}.id`}
            placeholder="Select Device"
          >
            <DeviceDropdown />
          </FastField>
        </TableRowCell>
        <td />
        <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
          <div className="flex items-center space-x-4">
            {typeof removeCallback !== 'undefined' && (
              <span aria-label="Edit" onClick={() => removeCallback(index)}>
                <FaTrash className="w-5 h-5" aria-hidden="true" />
              </span>
            )}
          </div>
        </td>
      </tr>
      <FieldArray name={`${formNamePrefix}.value`}>
        {({ remove, push }) => (
          <tr className="bg-gray-50">
            <TableRowCell className="text-gray-500" colSpan={4}>
              <div>
                {rule?.value.map((sequenceValue, index) => (
                  <div key={index} className="flex items-center space-x-4 py-2">
                    <FastField
                      key={`${formNamePrefix}.value.${index}`}
                      name={`${formNamePrefix}.value.${index}`}
                      className="block w-full sm:text-sm sm:leading-5"
                      type="text"
                    />
                    <span aria-label="Edit" onClick={() => remove(index)}>
                      <FaTrash className="w-5 h-5" aria-hidden="true" />
                    </span>
                  </div>
                ))}
                <div className="flex items-center justify-center pt-4">
                  <Button type="button" onClick={() => push('')}>
                    Add new sequence
                  </Button>
                </div>
              </div>
            </TableRowCell>
          </tr>
        )}
      </FieldArray>
    </>
  )
}

export default RuleSequenceEdit
