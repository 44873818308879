import React, { FunctionComponent } from 'react'
import { SequenceOperation } from 'types/operator'
import { FastField, FieldArray } from 'formik'
import DeviceDropdown from 'modules/devices/deviceDropdownContainer'
import { FaTrash } from 'react-icons/fa'
import Button from 'modules/common/buttons/button'
import SelectField from 'modules/common/form/selectField'
import { TableHeadCell, TableRowCell } from 'modules/common/table/table'
import BooleanField from 'modules/common/form/booleanField'

type Props = {
  operation: SequenceOperation
  index: number
  removeCallback?<T>(index: number): T | undefined
  formNamePrefix: string
}

const OperationSequenceEdit: FunctionComponent<Props> = ({
  operation,
  index,
  removeCallback,
  formNamePrefix,
}) => {
  return (
    <>
      <tr className="bg-white">
        <TableRowCell className="text-gray-500">
          <FastField
            component={SelectField}
            name={`${formNamePrefix}.id`}
            placeholder="Select Device"
          >
            <DeviceDropdown />
          </FastField>
        </TableRowCell>
        <td />
        <TableRowCell className="text-gray-500">
          <FastField
            component={BooleanField}
            name={`${formNamePrefix}.runOnce`}
          />
        </TableRowCell>
        <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
          <div className="flex items-center space-x-4">
            {typeof removeCallback !== 'undefined' && (
              <span aria-label="Edit" onClick={() => removeCallback(index)}>
                <FaTrash className="w-5 h-5" aria-hidden="true" />
              </span>
            )}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={4}>
          <table className="min-w-full w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <TableHeadCell>Value</TableHeadCell>
                <TableHeadCell>Duration after (ms)</TableHeadCell>
                <TableHeadCell>Actions</TableHeadCell>
              </tr>
            </thead>
            <tbody>
              <FieldArray name={`${formNamePrefix}.value`}>
                {({ remove, push }) => (
                  <>
                    {operation?.value.map((sequenceValue, index) => (
                      <tr key={index} className="bg-gray-50">
                        <TableRowCell className="text-gray-500">
                          <FastField
                            key={`${formNamePrefix}.value.${index}.value`}
                            name={`${formNamePrefix}.value.${index}.value`}
                            className="block w-full sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </TableRowCell>
                        <TableRowCell className="text-gray-500">
                          <FastField
                            key={`${formNamePrefix}.value.${index}.interval`}
                            name={`${formNamePrefix}.value.${index}.interval`}
                            className="block w-full sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </TableRowCell>
                        <TableRowCell className="text-gray-500">
                          <span aria-label="Edit" onClick={() => remove(index)}>
                            <FaTrash className="w-5 h-5" aria-hidden="true" />
                          </span>
                        </TableRowCell>
                      </tr>
                    ))}
                    <tr>
                      <TableRowCell colSpan={3}>
                        <div className="flex items-center justify-center pt-4">
                          <Button
                            type="button"
                            onClick={() => push({ value: '', interval: 100 })}
                          >
                            Add new sequence
                          </Button>
                        </div>
                      </TableRowCell>
                    </tr>
                  </>
                )}
              </FieldArray>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  )
}

export default OperationSequenceEdit
