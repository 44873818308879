import React, { FunctionComponent } from 'react'
import { Room } from 'types/room'
import { Link } from 'gatsby'

interface Props {
  room?: Room
}

export const RoomTabs: FunctionComponent<Props> = ({ room }) => {
  return (
    <div className="bg-white mt-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        >
          <option>My Account</option>
          <option>Company</option>
          <option>Team Members</option>
          <option>Billing</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          {typeof room !== 'undefined' && (
            <nav className="-mb-px flex" aria-label="Tabs">
              <Link
                to={`/app/rooms/${room.id}/`}
                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                activeClassName="border-indigo-500 text-indigo-600 hover:border-indigo-500 hover:text-indigo-600"
              >
                Room
              </Link>
              <Link
                to={`/app/rooms/${room.id}/history`}
                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                activeClassName="border-indigo-500 text-indigo-600"
              >
                Game History
              </Link>
              <Link
                to={`/app/rooms/${room.id}/tips`}
                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                activeClassName="border-indigo-500 text-indigo-600"
              >
                Tips
              </Link>
            </nav>
          )}
        </div>
      </div>
    </div>
  )
}
