import React, { FunctionComponent } from 'react'
import { RoomComponentProps } from 'types/room'
import { RouteComponentProps } from '@reach/router'
import RoomContainer from 'modules/room/roomContainer'
import GameHistoryContainer from 'modules/gameHistory/gameHistoryContainer'

const RoomHistory: FunctionComponent<
  RouteComponentProps<RoomComponentProps>
> = ({ roomId }) => {
  return (
    <>
      <RoomContainer roomId={roomId}>
        <div className="mb-8">
          <GameHistoryContainer roomId={roomId} />
        </div>
      </RoomContainer>
    </>
  )
}

export default RoomHistory
