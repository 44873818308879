import React, { FunctionComponent, useEffect, useState } from 'react'
import { Operator, Rule } from 'types/operator'
import { FastField } from 'formik'
import DeviceDropdown from 'modules/devices/deviceDropdownContainer'
import { FaTrash } from 'react-icons/fa'
import OperatorDropdown from 'modules/operator/operatorDropdownContainer'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { Device, DeviceLibrary } from 'types/device'
import SelectField from 'modules/common/form/selectField'
import { TableRowCell } from 'modules/common/table/table'
import { FieldTypeByLibrary } from 'modules/operator/components/fieldTypeByLibrary'

type Props = {
  rule?: Rule
  index: number
  removeCallback?<T>(index: number): T | undefined
  devices?: Device[]
  operators?: Operator[]
  formNamePrefix: string
}

const RuleEdit: FunctionComponent<Props> = ({
  rule,
  index,
  removeCallback,
  devices,
  operators,
  formNamePrefix,
}) => {
  const [ruleLibrary, setRuleLibrary] = useState<DeviceLibrary>('boolean')

  useEffect(() => {
    if (devices && rule && 'device' === rule.type) {
      const deviceFound = devices.find(device => device.id === rule.id)
      if (typeof deviceFound !== 'undefined') {
        setRuleLibrary(deviceFound.library)
      }
    }
    if (operators && rule && 'operator' === rule.type) {
      const operatorFound = operators.find(operator => operator.id === rule.id)
      if (typeof operatorFound !== 'undefined') {
        setRuleLibrary('boolean')
      }
    }
  }, [devices, operators, rule])

  return (
    <tr className="bg-white">
      <TableRowCell className="text-gray-500">
        <FastField
          component={SelectField}
          name={`${formNamePrefix}.type`}
          placeholder="Type"
          value="device"
        >
          <option value="device">Device</option>
        </FastField>
      </TableRowCell>
      <TableRowCell className="text-gray-500">
        <FastField
          component={SelectField}
          name={`${formNamePrefix}.id`}
          placeholder="Select Device/operator"
        >
          {rule?.type === 'device' && <DeviceDropdown />}
          {rule?.type === 'operator' && <OperatorDropdown />}
        </FastField>
      </TableRowCell>
      <TableRowCell className="text-gray-500">
        <FieldTypeByLibrary
          formNamePrefix={formNamePrefix}
          ruleLibrary={ruleLibrary}
        />
      </TableRowCell>
      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
        <div className="flex items-center space-x-4">
          {typeof removeCallback !== 'undefined' && (
            <span aria-label="Edit" onClick={() => removeCallback(index)}>
              <FaTrash className="w-5 h-5" aria-hidden="true" />
            </span>
          )}
        </div>
      </td>
    </tr>
  )
}
const mapStateToProps = (state: AppState) => ({
  devices: state.devices.data,
  operators: state.currentRoom.data?.operators,
})

export default connect(mapStateToProps)(RuleEdit)
