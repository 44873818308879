import React, { FunctionComponent } from 'react'
import { AppState } from 'state/rootReducer'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import Button from 'modules/common/buttons/button'
import { AppMessage } from 'modules/messages/state/types'
import { addMessage } from 'modules/messages/state/actions'
import { Device } from 'types/device'
import { ExtraDeviceEdit } from 'modules/room/components/extraDeviceEdit'
import { addExtraDevicesRequest } from 'modules/room/state/actions'
import { TableHeadCell, TableRowCell } from 'modules/common/table/table'
import { CardHeader } from 'modules/common/cards/cardHeader'

const emptyDevice: Device = { id: '', library: 'boolean', name: '' }

const ExtraDevicesEditContainer: FunctionComponent<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({ roomId, addExtraDevices, extraDevices }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg my-4">
      <CardHeader>Extra Devices</CardHeader>
      <div className="px-4 py-5 sm:p-6">
        <Formik
          initialValues={{
            extraDevices: extraDevices || [emptyDevice],
          }}
          onSubmit={(
            values: { extraDevices: Device[] },
            {
              setSubmitting,
            }: FormikHelpers<{
              extraDevices: Device[]
            }>
          ) => {
            if (typeof roomId !== 'undefined') {
              addExtraDevices({ extraDevices: values.extraDevices, roomId })
            }
            setSubmitting(false)
          }}
        >
          {({ values }) => (
            <Form>
              <table className="min-w-full w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <TableHeadCell>Device</TableHeadCell>
                    <TableHeadCell>Actions</TableHeadCell>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name={`extraDevices`}>
                    {({ remove, push }) => (
                      <>
                        {values.extraDevices?.map((device, index) => (
                          <ExtraDeviceEdit
                            key={`extraDevices.${index}`}
                            index={index}
                            removeCallback={remove}
                          />
                        ))}
                        <tr className="bg-white">
                          <TableRowCell
                            colSpan={5}
                            className="font-medium text-gray-900 border-dashed border-t text-center"
                          >
                            <Button
                              type="button"
                              onClick={() => push(emptyDevice)}
                            >
                              Add new Extra Device
                            </Button>
                          </TableRowCell>
                        </tr>
                      </>
                    )}
                  </FieldArray>
                </tbody>
              </table>
              <Button type="submit">Save </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  extraDevices: state.devices.data?.filter(device =>
    state.currentRoom?.data?.links?.includes(device.id)
  ),
  roomId: state.currentRoom.data?.id,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    addMessage: ({ message }: { message: AppMessage }) =>
      dispatch(addMessage({ message })),
    addExtraDevices: ({
      roomId,
      extraDevices,
    }: {
      roomId: string
      extraDevices: Device[]
    }) => dispatch(addExtraDevicesRequest({ roomId, extraDevices })),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtraDevicesEditContainer)
