import React, { FunctionComponent } from 'react'
import { Device } from 'types/device'
import { TableRowCell } from 'modules/common/table/table'

type Props = {
  sourceDevice?: Device
  targetDevice?: Device
}

export const RelayView: FunctionComponent<Props> = ({
  sourceDevice,
  targetDevice,
}) => {
  return (
    <tr className="bg-white">
      <TableRowCell className="text-gray-500">
        {typeof sourceDevice !== 'undefined' && sourceDevice.name}
      </TableRowCell>
      <TableRowCell className="font-medium text-gray-900">
        {typeof targetDevice !== 'undefined' && targetDevice.name}
      </TableRowCell>
    </tr>
  )
}
