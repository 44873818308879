import React, { FunctionComponent, useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AppMessage } from 'modules/messages/state/types'
import { addMessage } from 'modules/messages/state/actions'
import { connect } from 'react-redux'
import { createRequest } from 'modules/devices/state/actions'

type Props = {
  deviceId: string
  addMessage: ({ message }: { message: AppMessage }) => any
  registerDevice: ({ deviceId }: { deviceId: string }) => any
}

const RegisterDeviceContainer: FunctionComponent<
  RouteComponentProps<Props>
> = ({ deviceId, addMessage, registerDevice }) => {
  const [isRegistering, setIsRegistering] = useState(false)

  const tryToRegister = async ({ deviceId }: { deviceId: string }) => {
    if (typeof registerDevice !== 'undefined') {
      setIsRegistering(true)
      registerDevice({ deviceId })
      setIsRegistering(false)
      await navigate('/app/devices')
    }
  }

  useEffect(() => {
    if (typeof deviceId !== 'undefined' && null !== deviceId) {
      tryToRegister({ deviceId })
    } else {
      const message: AppMessage = {
        body: 'Invalid DeviceID given',
        type: 'danger',
      }
      if (typeof addMessage !== 'undefined') {
        addMessage({ message })
      }
    }
  }, [])

  return <>{isRegistering && <div>TRYING TO REGISTER DEVICE</div>}</>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    addMessage: ({ message }: { message: AppMessage }) =>
      dispatch(addMessage({ message })),
    registerDevice: ({ deviceId }: { deviceId: string }) =>
      dispatch(createRequest({ deviceId })),
  }
}

export default connect(null, mapDispatchToProps)(RegisterDeviceContainer)
